import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import imgProvPagata from "../../img/Provigion3Min.jpg";
import imgProvPagataParziale from "../../img/Provigion1Min.jpg";
import imgProvDaPagare from "../../img/Provigion2Min.jpg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Accordion, AccordionDetails, AccordionSummary, Toolbar, Typography } from '@material-ui/core';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import {GetTextStatoOrdineCert,isDate,DateDiff, GetIDCommerciale,GetToken,GetRole,ccyFormat, get_years,GetItemStatoOrdineCert, GetItemLast10Year, GetIDIspettore, GetExtensionFile, GetBlobTypeByExtension, GetGradoCommerciale} from "../../Modules/Funzioni";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import Avatar from '@material-ui/core/Avatar';
import MyDatePicker from '../MyDatePicker';
import { Redirect } from "react-router-dom";
import { Ruolo_Commerciale,Ruolo_Admin, Stato_Annullato, Ruolo_Ispettore, Ruolo_CommTecnico, Grado_Direttore, Grado_Executive, Grado_Partner, Grado_Manager, Grado_Specialist } from '../../Modules/Costanti';
import FilterListIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ReactExport from "react-export-excel";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(41, 110, 156, 1) !important",
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));

OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open, fileName, fileNameRicevuta, rinnovoId } = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            <ListItem button onClick={() => handleListItemClick("Apri")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <FilterListIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Ordine (Servizi)</ListItemText>
            </ListItem>
            {
            fileName!=="" && fileName!==null  &&
            (<ListItem button onClick={() => handleListItemClick("Scarica")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica PDF Ordine</ListItemText>
            </ListItem>)
            }
            {
            fileNameRicevuta!=="" && fileNameRicevuta!==null  &&
            (<ListItem button onClick={() => handleListItemClick("Ricevuta")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica Ricevuta Pagamento</ListItemText>
            </ListItem>)
            }
            {
                (parseInt(rinnovoId) || 0) === 0 && (
                    <ListItem button onClick={() => handleListItemClick("Duplica")}>
                    <ListItemAvatar>
                        <Avatar className={classes.BtnEdit}>
                            <NoteAddIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>Rinnova Ordine</ListItemText>
                    </ListItem>
                )
            }
            
        </List>
      </Dialog>
    );
  }

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'DescImpianto', string: false, disablePadding: true, label: 'Impianto' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'C.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'SpesaCommerciale', string: false, disablePadding: true, label: 'Sp C' },
];

const headCellsExecutive = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'C.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'SpesaCommerciale', string: false, disablePadding: true, label: 'Sp C' },
    { id: 'ProvvigioneExecutive', string: false, disablePadding: true, label: '% E' },
];

const headCellsPartner = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'C.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'SpesaCommerciale', string: false, disablePadding: true, label: 'Sp C' },
    { id: 'ProvvigionePartner', string: false, disablePadding: true, label: '% P' },
];

const headCellsManager = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'C.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'SpesaCommerciale', string: false, disablePadding: true, label: 'Sp C' },
    { id: 'ProvvigioneManager', string: false, disablePadding: true, label: '% M' },
];

const headCellsScadenze = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'DataScadenza', string: false, disablePadding: true, label: 'Scadenza' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
];

const headCellsAdmin = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'Comm.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'SpesaCommerciale', string: false, disablePadding: true, label: 'Sp C' },
    { id: 'ProvvigioneDirettore', string: false, disablePadding: true, label: '% D' },
    { id: 'SpesaDirettore', string: false, disablePadding: true, label: 'Sp D' },
];
const headCellsAdminScadenze = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'DataScadenza', string: false, disablePadding: true, label: 'Scadenza' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    
];

function GetHeadCells(_userRole,_GradoCommerciale){
    if (parseInt(_userRole)===Ruolo_Admin || _GradoCommerciale===Grado_Direttore){
        return headCellsAdmin;
    }
    else {
        switch (_GradoCommerciale){
            case Grado_Executive:
                return headCellsExecutive;
            case Grado_Partner:
                return headCellsPartner;
            case Grado_Manager:
                return headCellsManager;
            case Grado_Specialist:
                return headCells;
        }
        
    }
}
function GetHeadCellsScadenze(_userRole,_GradoCommerciale){
    if (parseInt(_userRole)===Ruolo_Admin || _GradoCommerciale===Grado_Direttore){
        return headCellsAdminScadenze;
    }
    else {
        switch (_GradoCommerciale){
            case Grado_Executive:
            case Grado_Partner:
            case Grado_Manager:
            case Grado_Specialist:
                return headCellsScadenze;
        }
        
    }
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, isScadenze } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {(() => {
                let _UserRole =GetRole(localStorage.getItem("token"));
                let _GradoCommerciale = GetGradoCommerciale(localStorage.getItem("token"));
                let _headCells=isScadenze ? GetHeadCellsScadenze(_UserRole, _GradoCommerciale) : GetHeadCells(_UserRole, _GradoCommerciale);
               
                return(_headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                )));
                })()}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    isScadenze: PropTypes.bool.isRequired,
};
const GetImponibileRow = (_PrezzoTotale,_Sconto, _Annullato) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
    }
    return ccyFormat(Prezzo);
}
const GetProvvigioneRow = (_PrezzoTotale,_Sconto, _Annullato, _Provvigione) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
    }
    Prezzo=Prezzo*parseFloat(_Provvigione)/100;
    return ccyFormat(Prezzo);
}
const GetSpesaRow = (_Spesa, _Annullato) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_Spesa);
    }
    return ccyFormat(Prezzo);
}
const GetSpese = (_SpesaKm, _CostoSpesa) =>{
    let Costo=parseFloat(_SpesaKm) + parseFloat(_CostoSpesa);
    return ccyFormat(Costo);
}
const GetPrezzoRow = (_PrezzoTotale,_Sconto,_CodiceIva, _Annullato, _SpeseKm, _CostoSpesa,_CodiceIvaSpesa) =>{
    let PrezzoIvato=0;
    if (parseFloat(_Annullato)!==1){
        let Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
        PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100))
        let PrezzoSpese = parseFloat(_SpeseKm)+ parseFloat(_CostoSpesa);
        let PrezzoSpeseIvato = PrezzoSpese + (PrezzoSpese*parseFloat(_CodiceIvaSpesa/100));
        PrezzoIvato=PrezzoIvato + PrezzoSpeseIvato;
    }
    return ccyFormat(PrezzoIvato);
}
const GetPagatoRow = (_PrezzoTotale,_Sconto,_CodiceIva, _Annullato,_Acconto,_Saldo, _SpeseKm, _CostoSpesa,_CodiceIvaSpesa) =>{
    let PrezzoIvato=0;
    if (parseFloat(_Annullato)!==1){
        if (parseFloat(_Saldo)===1){
            let Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
            PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100));
            let PrezzoSpese = parseFloat(_SpeseKm)+ parseFloat(_CostoSpesa);
            let PrezzoSpeseIvato = PrezzoSpese + (PrezzoSpese*parseFloat(_CodiceIvaSpesa/100));
            PrezzoIvato=PrezzoIvato + PrezzoSpeseIvato;

        }else{
            if (parseFloat(_Acconto)>0){
                PrezzoIvato +=parseFloat(_Acconto);
            }
        }
    }
    return ccyFormat(PrezzoIvato);
}
function ExportToExcelAdmin (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Ordini">
                <ExcelColumn label="N." value="NumOrdine"/>
                <ExcelColumn label="Data" value="DataOrdine"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                <ExcelColumn label="Impianto" value="DescImpianto"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Spese"
                            value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa,col.CodiceIvaSpesa)}/>
                <ExcelColumn label="Pagato"
                             value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa,col.CodiceIvaSpesa)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                col.Saldo,
                                col.Annullato,
                                col.Firmato,
                                col.FirmaInVerifica,
                                col.DataVerifica,
                                col.NumDayScadenza,
                                col.GGScadPrimoAvviso,
                                col.Rimandato,
                                col.Rinnovo_id)}/>
                <ExcelColumn label="Provv. Commerciale"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                <ExcelColumn label="Spesa Commerciale"
                    value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
                <ExcelColumn label="Provv. Direttore"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneDirettore)}/>
                <ExcelColumn label="Spesa Direttore"
                    value={(col) => GetSpesaRow(col.SpesaDirettore,col.Annullato)}/>
                <ExcelColumn label="Provv. Executive"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneExecutive)}/>
                <ExcelColumn label="Provv. Partner"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigionePartner)}/>
                <ExcelColumn label="Provv. Manager"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneManager)}/>
                <ExcelColumn label="Provv. Ispettore"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneIspettore)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}

function ExportToExcelScadenze (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Scadenze">
                <ExcelColumn label="N." value="NumOrdine"/>
                <ExcelColumn label="Data" value="DataOrdine"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                <ExcelColumn label="Impianto" value="DescImpianto"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Spese"
                            value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa,col.CodiceIvaSpesa)}/>
                <ExcelColumn label="Pagato"
                             value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa,col.CodiceIvaSpesa)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                col.Saldo,
                                col.Annullato,
                                col.Firmato,
                                col.FirmaInVerifica,
                                col.DataVerifica,
                                col.NumDayScadenza,
                                col.GGScadPrimoAvviso,
                                col.Rimandato,
                                col.Rinnovo_id)}/>
                <ExcelColumn label="Scadenza" value="DataScadenza"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

function ExportToExcelCommerciale (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const _GradoCommerciale = GetGradoCommerciale(localStorage.getItem("token"));
    switch (_GradoCommerciale) {
        case Grado_Executive:
            return (
                <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
                <ExcelSheet data={props.data} name="Ordini">
                    <ExcelColumn label="N." value="NumOrdine"/>
                    <ExcelColumn label="Data" value="DataOrdine"/>
                    <ExcelColumn label="Azienda" value="RagioneSociale"/>
                    <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                    <ExcelColumn label="Impianto" value="DescImpianto"/>
                    <ExcelColumn label="Imponibile"
                                value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                    <ExcelColumn label="IVA" value="CodiceIva"/>
                    <ExcelColumn label="Spese"
                                value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Prezzo"
                                value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Pagato"
                                value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Stato"
                                value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                    col.Saldo,
                                    col.Annullato,
                                    col.Firmato,
                                    col.FirmaInVerifica,
                                    col.DataVerifica,
                                    col.NumDayScadenza,
                                    col.GGScadPrimoAvviso,
                                    col.Rimandato,
                                    col.Rinnovo_id)}/>
                    <ExcelColumn label="Provv. Commerciale"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                    <ExcelColumn label="Spesa Commerciale"
                        value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
                    <ExcelColumn label="Provv. Executive"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneExecutive)}/>
                    <ExcelColumn label="Provv. Partner"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigionePartner)}/>
                    <ExcelColumn label="Provv. Manager"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneManager)}/>
                </ExcelSheet>
            </ExcelFile>);
        case Grado_Partner:
            return (
                <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
                <ExcelSheet data={props.data} name="Ordini">
                    <ExcelColumn label="N." value="NumOrdine"/>
                    <ExcelColumn label="Data" value="DataOrdine"/>
                    <ExcelColumn label="Azienda" value="RagioneSociale"/>
                    <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                    <ExcelColumn label="Impianto" value="DescImpianto"/>
                    <ExcelColumn label="Imponibile"
                                value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                    <ExcelColumn label="IVA" value="CodiceIva"/>
                    <ExcelColumn label="Spese"
                                value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Prezzo"
                                value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Pagato"
                                value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Stato"
                                value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                    col.Saldo,
                                    col.Annullato,
                                    col.Firmato,
                                    col.FirmaInVerifica,
                                    col.DataVerifica,
                                    col.NumDayScadenza,
                                    col.GGScadPrimoAvviso,
                                    col.Rimandato,
                                    col.Rinnovo_id)}/>
                    <ExcelColumn label="Provv. Commerciale"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                    <ExcelColumn label="Spesa Commerciale"
                        value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
                    <ExcelColumn label="Provv. Partner"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigionePartner)}/>
                    <ExcelColumn label="Provv. Manager"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneManager)}/>
                </ExcelSheet>
            </ExcelFile>);
        case Grado_Manager:
            return (
                <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
                <ExcelSheet data={props.data} name="Ordini">
                    <ExcelColumn label="N." value="NumOrdine"/>
                    <ExcelColumn label="Data" value="DataOrdine"/>
                    <ExcelColumn label="Azienda" value="RagioneSociale"/>
                    <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                    <ExcelColumn label="Impianto" value="DescImpianto"/>
                    <ExcelColumn label="Imponibile"
                                value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                    <ExcelColumn label="IVA" value="CodiceIva"/>
                    <ExcelColumn label="Spese"
                                value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Prezzo"
                                value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Pagato"
                                value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Stato"
                                value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                    col.Saldo,
                                    col.Annullato,
                                    col.Firmato,
                                    col.FirmaInVerifica,
                                    col.DataVerifica,
                                    col.NumDayScadenza,
                                    col.GGScadPrimoAvviso,
                                    col.Rimandato,
                                    col.Rinnovo_id)}/>
                    <ExcelColumn label="Provv. Commerciale"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                    <ExcelColumn label="Spesa Commerciale"
                        value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
                    <ExcelColumn label="Provv. Manager"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneManager)}/>
                </ExcelSheet>
            </ExcelFile>);
        case Grado_Specialist:
            return (
                <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
                <ExcelSheet data={props.data} name="Ordini">
                    <ExcelColumn label="N." value="NumOrdine"/>
                    <ExcelColumn label="Data" value="DataOrdine"/>
                    <ExcelColumn label="Azienda" value="RagioneSociale"/>
                    <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                    <ExcelColumn label="Impianto" value="DescImpianto"/>
                    <ExcelColumn label="Imponibile"
                                value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                    <ExcelColumn label="IVA" value="CodiceIva"/>
                    <ExcelColumn label="Spese"
                                value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Prezzo"
                                value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Pagato"
                                value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa)}/>
                    <ExcelColumn label="Stato"
                                value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                    col.Saldo,
                                    col.Annullato,
                                    col.Firmato,
                                    col.FirmaInVerifica,
                                    col.DataVerifica,
                                    col.NumDayScadenza,
                                    col.GGScadPrimoAvviso,
                                    col.Rimandato,
                                    col.Rinnovo_id)}/>
                    <ExcelColumn label="Provv. Commerciale"
                        value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                    <ExcelColumn label="Spesa Commerciale"
                        value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
                </ExcelSheet>
            </ExcelFile>);
    }
    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Ordini">
                <ExcelColumn label="N." value="NumOrdine"/>
                <ExcelColumn label="Data" value="DataOrdine"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                <ExcelColumn label="Impianto" value="DescImpianto"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Spese"
                            value={(col) => GetSpese(col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Pagato"
                             value={(col) => GetPagatoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo,col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoOrdineCert(col.Approvato,
                                col.Saldo,
                                col.Annullato,
                                col.Firmato,
                                col.FirmaInVerifica,
                                col.DataVerifica,
                                col.NumDayScadenza,
                                col.GGScadPrimoAvviso,
                                col.Rimandato,
                                col.Rinnovo_id)}/>
                <ExcelColumn label="Provv. Commerciale"
                    value={(col) => GetProvvigioneRow(col.PrezzoTotale,col.Sconto,col.Annullato,col.ProvvigioneCommerciale)}/>
                <ExcelColumn label="Spesa Commerciale"
                    value={(col) => GetSpesaRow(col.SpesaCommerciale,col.Annullato)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('desc');
  const [orderScadenze, setOrderScadenze] = React.useState('desc');
  let tokenRicerca=localStorage.getItem("tokenRicercaOrdini");
  let tokenRicercaScadenze=localStorage.getItem("tokenRicercaOrdiniScadenze");
  let tmpRicStato="";
  let tmpRicIDAzienda="";
  let tmpRicAzienda="";
  let tmpRicIDImpianto="";
  let tmpRicImpianto="";
  let tmpRicIDCommerciale="";
  let tmpRicCommerciale="";
  let tmpRicPagato="";
  let tmpRicModalPag="";
  let tmpRicPartitaIva="";
  let tmpRicDataOrdineInizio="";
  let tmpRicDataOrdineFine="";
  let tmpRicSubalterno=false;
  let tmpRicAnnoCompetenza=(new Date().getFullYear());
  let tmpRicNumRecord="100";
  let tmpPage=0;
  let tmpRicTipoLuogo="0";
  let tmpRicLuogo="";
  let tmpRicPrezzo="";
  let tmpRicUrlLuogo=process.env.REACT_APP_API_URL+"province";
  let tmpRicOptionLuogoName="Provincia";
  let tmpRicOptionLuogoValue="CodProv";
  let tmpSelectedTab="1";

  let tmpRicIDAziendaScadenze="";
  let tmpRicAziendaScadenze="";
  let tmpRicIDImpiantoScadenze="";
  let tmpRicImpiantoScadenze="";
  let tmpRicIDCommercialeScadenze="";
  let tmpRicCommercialeScadenze="";
  let tmpRicPartitaIvaScadenze="";
  let tmpRicDataScadenzaInizio="";
  let tmpRicDataScadenzaFine="";
  let tmpRicNumRecordScadenze="100";
  let tmpPageScadenze=0;
  let tmpRicTipoLuogoScadenze="0";
  let tmpRicLuogoScadenze="";
  let tmpRicUrlLuogoScadenze=process.env.REACT_APP_API_URL+"province";
  let tmpRicOptionLuogoScadenzeName="Provincia";
  let tmpRicOptionLuogoScadenzeValue="CodProv";

    if (tokenRicerca!=="" && tokenRicerca!==null){
        let mtxTokenRicerca=tokenRicerca.split("|");
        tmpRicStato=mtxTokenRicerca[0];
        tmpRicIDAzienda=mtxTokenRicerca[1];
        tmpRicAzienda=mtxTokenRicerca[2];
        tmpRicIDImpianto=mtxTokenRicerca[3];
        tmpRicImpianto=mtxTokenRicerca[4];
        tmpRicIDCommerciale=mtxTokenRicerca[5];
        tmpRicCommerciale=mtxTokenRicerca[6];
        tmpRicPagato=mtxTokenRicerca[7];
        tmpRicDataOrdineInizio=mtxTokenRicerca[8];
        tmpRicDataOrdineFine=mtxTokenRicerca[9];
        tmpRicSubalterno=mtxTokenRicerca[10]==="true";
        tmpPage=parseInt(mtxTokenRicerca[11]);
        tmpRicAnnoCompetenza=mtxTokenRicerca[12];
        tmpRicNumRecord=mtxTokenRicerca[13];
        tmpRicModalPag=mtxTokenRicerca[14];
        tmpRicTipoLuogo=mtxTokenRicerca[15];
        tmpRicLuogo=mtxTokenRicerca[16];
        tmpRicUrlLuogo=mtxTokenRicerca[17];
        tmpRicOptionLuogoName=mtxTokenRicerca[18];
        tmpRicOptionLuogoValue=mtxTokenRicerca[19];
        tmpRicPrezzo=mtxTokenRicerca[20];
        tmpRicPartitaIva=mtxTokenRicerca[21];
        tmpSelectedTab=String(mtxTokenRicerca[22]);

    }

  const [NewOrdine, setNewOrdine] = React.useState(false);
  const [RicAzienda, setRicAzienda] = React.useState(tmpRicAzienda);
  const [RicIDAzienda, setRicIDAzienda] = React.useState(tmpRicIDAzienda);
  const [RicImpianto, setRicImpianto] = React.useState(tmpRicImpianto);
  const [RicIDImpianto, setRicIDImpianto] = React.useState(tmpRicIDImpianto);
  const [RicCommerciale, setRicCommerciale] = React.useState(tmpRicCommerciale);
  const [RicSubalterno, setRicSubalterno] = React.useState(tmpRicSubalterno);
  const [RicSubalternoScadenze, setRicSubalternoScadenze] = React.useState(false);
  const [RicIDCommerciale, setRicIDCommerciale] = React.useState(tmpRicIDCommerciale);
  const [RicAnnoCompetenza, setRicAnnoCompetenza] = React.useState(tmpRicAnnoCompetenza);
  const [RicNumRecord, setRicNumRecord] = React.useState(tmpRicNumRecord);
  const [RicStato, setRicStato] = React.useState(tmpRicStato);
  const [RicPagato, setRicPagato] = React.useState(tmpRicPagato);
  const [RicModalPag, setRicModalPag] = React.useState(tmpRicModalPag);
  const [RicPartitaIva, setRicPartitaIva] = React.useState(tmpRicPartitaIva);
  const [RicDataOrdineInizio, setRicDataOrdineInizio] = React.useState(tmpRicDataOrdineInizio);
  const [RicDataOrdineFine, setRicDataOrdineFine] = React.useState(tmpRicDataOrdineFine);
  const [RicTextLuogo, setRicTextLuogo] = React.useState(tmpRicLuogo);
  const [RicTipoLuogo, setRicTipoLuogo] = React.useState(tmpRicTipoLuogo);
  const [RicLuogo, setRicLuogo] = React.useState(tmpRicLuogo);
  const [RicOptionLuogoValue, setRicOptionLuogoValue] = useState(tmpRicOptionLuogoValue);
  const [RicOptionLuogoName, setRicOptionLuogoName] = useState(tmpRicOptionLuogoName);
  const [RicUrlLuogo, setRicUrlLuogo] = useState(tmpRicUrlLuogo);
  const [RicPrezzo, setRicPrezzo] = useState(tmpRicPrezzo);
  const [page, setPage] = React.useState(tmpPage); 

  if (tokenRicercaScadenze!=="" && tokenRicercaScadenze!==null){
    let mtxTokenRicerca=tokenRicercaScadenze.split("|");
    tmpRicIDAziendaScadenze=mtxTokenRicerca[0];
    tmpRicAziendaScadenze=mtxTokenRicerca[1];
    tmpRicIDImpiantoScadenze=mtxTokenRicerca[2];
    tmpRicImpiantoScadenze=mtxTokenRicerca[3];
    tmpRicIDCommercialeScadenze=mtxTokenRicerca[4];
    tmpRicCommercialeScadenze=mtxTokenRicerca[5];
    tmpRicDataScadenzaInizio=mtxTokenRicerca[6];
    tmpRicDataScadenzaFine=mtxTokenRicerca[7];
    tmpPageScadenze=parseInt(mtxTokenRicerca[8]);
    tmpRicNumRecordScadenze=mtxTokenRicerca[9];
    tmpRicTipoLuogoScadenze=mtxTokenRicerca[10];
    tmpRicLuogoScadenze=mtxTokenRicerca[11];
    tmpRicUrlLuogoScadenze=mtxTokenRicerca[12];
    tmpRicOptionLuogoScadenzeName=mtxTokenRicerca[13];
    tmpRicOptionLuogoScadenzeValue=mtxTokenRicerca[14];
    tmpRicPartitaIvaScadenze=mtxTokenRicerca[15];
}
  //scadenze
  const [RicPartitaIvaScadenze, setRicPartitaIvaScadenze] = React.useState(tmpRicPartitaIvaScadenze);
  const [RicTextLuogoScadenze, setRicTextLuogoScadenze] = React.useState(tmpRicLuogoScadenze);
  const [RicTipoLuogoScadenze, setRicTipoLuogoScadenze] = React.useState(tmpRicTipoLuogoScadenze);
  const [RicLuogoScadenze, setRicLuogoScadenze] = React.useState(tmpRicLuogoScadenze);
  const [RicOptionLuogoScadenzeValue, setRicOptionLuogoScadenzeValue] = useState(tmpRicOptionLuogoScadenzeValue);
  const [RicOptionLuogoScadenzeName, setRicOptionLuogoScadenzeName] = useState(tmpRicOptionLuogoScadenzeName);
  const [RicUrlLuogoScadenze, setRicUrlLuogoScadenze] = useState(tmpRicUrlLuogoScadenze);
  const [RicDataScadenzaInizio, setRicDataScadenzaInizio] = React.useState(tmpRicDataScadenzaInizio);
  const [RicDataScadenzaFine, setRicDataScadenzaFine] = React.useState(tmpRicDataScadenzaFine);
  const [RicNumRecordScadenze, setRicNumRecordScadenze] = React.useState(tmpRicNumRecordScadenze);
  const [RicIDCommercialeScadenze, setRicIDCommercialeScadenze] = React.useState(tmpRicIDCommercialeScadenze);
  const [RicAziendaScadenze, setRicAziendaScadenze] = React.useState(tmpRicAziendaScadenze);
  const [RicIDAziendaScadenze, setRicIDAziendaScadenze] = React.useState(tmpRicIDAziendaScadenze);
  const [RicImpiantoScadenze, setRicImpiantoScadenze] = React.useState(tmpRicImpiantoScadenze);
  const [RicIDImpiantoScadenze, setRicIDImpiantoScadenze] = React.useState(tmpRicIDImpiantoScadenze);
  const [RicCommercialeScadenze, setRicCommercialeScadenze] = React.useState(tmpRicCommercialeScadenze);
  const [pageScadenze, setPageScadenze] = React.useState(tmpPageScadenze);

  const [orderBy, setOrderBy] = React.useState('id');
  const [orderByScadenze, setOrderByScadenze] = React.useState('id');    
  const [data, setData] = useState([]); 
  const [dataScadenze, setDataScadenze] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [DisableFilterScadenze, setDisableFilterScadenze] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(20);  
  const [rowsPerPageScadenze, setRowsPerPageScadenze] = React.useState(20);  
  const [dense, setDense] = React.useState(false);
  const [denseScadenze, setDenseScadenze] = React.useState(false);
  const [ModOrdine, setModOrdine] = useState(false);
  const [DuplicaOrdine, setDuplicaOrdine] = useState(false);
  const [SelIdOrdine, setSelIdOrdine] = useState("");
  const [SelIdAzienda, setSelIdAzienda] = useState("");
  const [SelIdImpianto, setSelIdImpianto] = useState("");
  const [SelVerificaExp, setSelVerificaExp] = useState("");
  const [SelVerificaMt, setSelVerificaMt] = useState("");
  const [SelNumOrdine, setSelNumOrdine] = useState("");
  const [SelRinnovoId, setSelRinnovoId] = useState("");
  const [SelDataOrdine, setSelDataOrdine] = useState("");
  const [SelFileName,setSelFileName]=useState("");
  const [SelFileNameRicevuta,setSelFileNameRicevuta]=useState("");

  const [PrezzoTotale, setPrezzoTotale] = useState(0);
  const [PrezzoTotaleScadenze, setPrezzoTotaleScadenze] = useState(0);
  const [ImponibileTotale, setImponibileTotale] = useState(0);
  const [ImponibileTotaleScadenze, setImponibileTotaleScadenze] = useState(0);
  const [SpeseTotaleScadenze, setSpeseTotaleScadenze] = useState(0);
  const [SpeseCommTotale, setSpeseCommTotale] = useState(0);
  const [ProvvCommTotale, setProvvCommTotale] = useState(0);
  const [ProvvDirTotale, setProvvDirTotale] = useState(0);
  const [ProvvExecutiveTotale, setProvvExecutiveTotale] = useState(0);
  const [ProvvPartnerTotale, setProvvPartnerTotale] = useState(0);
  const [ProvvManagerTotale, setProvvManagerTotale] = useState(0);
  const [ProvvIspettoriTotale, setProvvIspettoriTotale] = useState(0);
  const [SpeseDirTotale, setSpeseDirTotale] = useState(0);
  const [IncassoTotale, setIncassoTotale] = useState(0);
  const [IncassoTotaleScadenze, setIncassoTotaleScadenze] = useState(0);
  const [OpenOperDialog,setOpenOperDialog]=useState(false);
  
  const [SelectedTab, setSelectedTab] = React.useState(tmpSelectedTab);

  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
const UserRole =GetRole(localStorage.getItem("token"));
const GradoCommerciale =GetGradoCommerciale(localStorage.getItem("token"));
  //-----------
const toggleNewOrdine = () => {
    resetStorage();
    setNewOrdine(true);
}

function GetIconCommissione(SaldoCommerciale,SaldoDirettore){
    let mIcon=imgProvDaPagare;
    if (SaldoCommerciale===1 && SaldoDirettore===1){
        mIcon=imgProvPagata;
    }
    else {
        if (SaldoCommerciale===1 || SaldoDirettore===1){
            mIcon=imgProvPagataParziale;
        }
    }
    return <img style={{height:"15pt"}} src={mIcon} className="date-picker-icon" alt=""/>;
}

const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
};

const toggleOperDialog = (_IdOrdine,_IdAzienda,_IdImpianto,_FileName,_FileNameRicevuta,_NumOrdine,_DataOrdine,_VerificaExp,_VerificaMt, _RinnovoId) => {
    resetStorage();
    setSelIdOrdine(_IdOrdine);
    setSelIdAzienda(_IdAzienda);
    setSelIdImpianto(_IdImpianto);
    setSelFileName(_FileName);
    setSelFileNameRicevuta(_FileNameRicevuta);
    setSelNumOrdine(_NumOrdine);
    setSelDataOrdine(_DataOrdine);
    setOpenOperDialog(true);
    setSelVerificaExp(_VerificaExp);
    setSelVerificaMt(_VerificaMt);
    setSelRinnovoId(_RinnovoId);
}
    
const resetStorage = () =>{
    localStorage.setItem("idordine", "");
    localStorage.setItem("idordineold", "");
    localStorage.setItem("idazienda", "");
    localStorage.setItem("idimpianto", "");
    localStorage.setItem("verificamessaterra","0");
    localStorage.setItem("verificaexp","0");
    localStorage.setItem("menunew", "0");
    localStorage.setItem("tokenRicercaOrdini", RicStato + "|" + RicIDAzienda + "|" + 
    RicAzienda + "|" + RicIDImpianto + "|" + RicImpianto + "|" + RicIDCommerciale + "|" + 
    RicCommerciale + "|" + RicPagato + "|" + RicDataOrdineInizio + "|" + 
    RicDataOrdineFine + "|" + RicSubalterno + "|" + page + "|" + 
    RicAnnoCompetenza + "|" + RicNumRecord + "|" + RicModalPag + "|" + RicTipoLuogo + "|" + RicLuogo + "|" +
    RicUrlLuogo + "|" + RicOptionLuogoName  + "|" + RicOptionLuogoValue + "|" + RicPrezzo + "|" + RicPartitaIva+ "|" + SelectedTab);

    localStorage.setItem("tokenRicercaOrdiniScadenze", RicIDAziendaScadenze + "|" + 
        RicAziendaScadenze + "|" + RicIDImpiantoScadenze + "|" + RicImpiantoScadenze + "|" + RicIDCommercialeScadenze + "|" + 
        RicCommercialeScadenze  + "|" + RicDataScadenzaInizio + "|" + 
        RicDataScadenzaFine  + "|" + pageScadenze + "|" + 
        RicNumRecordScadenze + "|" + RicTipoLuogoScadenze + "|" + RicLuogoScadenze + "|" +
        RicUrlLuogoScadenze + "|" + RicOptionLuogoScadenzeName  + "|" + RicOptionLuogoScadenzeValue + "|" + RicPartitaIvaScadenze);
}

const DownloadRicevutaPagamento = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    let extension=GetExtensionFile(SelFileNameRicevuta);
    let blobType=GetBlobTypeByExtension(extension);

    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelFileNameRicevuta);
            fd.append("id", SelIdOrdine);
            let url=process.env.REACT_APP_API_URL+'ricevutapagamentoordinecert'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data], 
                    {type: blobType});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'RicevutaOrdine_' + SelNumOrdine + '_' + get_years(SelDataOrdine) + '.' + extension); 
                document.body.appendChild(link);
                link.click();
                link.remove();
                });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
}
  
const DownloadDocumento = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;


    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelFileName);
            fd.append("id", SelIdOrdine);
            let url=process.env.REACT_APP_API_URL+'docordinecert'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'ordine_' + SelNumOrdine + '_' + get_years(SelDataOrdine) + '.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
                });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
}

async function retrieveScadenzeOrdini() {
    let result=[];
    try{
        const token = GetToken(localStorage.getItem("token"));
        const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
        const IDIspettore = GetIDIspettore(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let url=process.env.REACT_APP_API_URL+'ordinecertscadenzelist';
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const fd = new FormData();
        if (UserRole===Ruolo_Commerciale || UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico){
            if (parseFloat(IDCommerciale)>0){
                fd.append("Commerciale_id", IDCommerciale); 
            } 
            if (parseFloat(IDIspettore)>0){
                fd.append("Ispettore_id", IDIspettore);    
            }  
        }
        fd.append("RicCommerciale_id", RicIDCommercialeScadenze);
        fd.append("IDAzienda", RicIDAziendaScadenze);
        fd.append("IDImpianto", RicIDImpiantoScadenze);
        fd.append("DataScadenzaInizio", RicDataScadenzaInizio);
        fd.append("DataScadenzaFine", RicDataScadenzaFine);
        fd.append("LastNumberData", RicNumRecordScadenze);
        fd.append("RicLuogo", RicLuogoScadenze);
        fd.append("RicTipoLuogo", RicTipoLuogoScadenze);
        if (RicSubalterno===true)  fd.append("Subalterno", "1");
        fd.append("RicPartitaIva", RicPartitaIvaScadenze);
                        
        //idazienda/0/codfiscale/0/cognome/0
        result = await ax.post(url,fd,axiosConfig);
    }
    catch(error) {
        let msg=""
        if (error.response!==undefined) {
            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
        }
        else
        {
            msg=GetMessage(undefined,"");
        }
        setMsgAlert(msg);
        setOpenAlert(true);

    }
    setIsLoading(false);    
    if (result.data!==undefined)    {
        setDataScadenze(result.data);
    }
    else {
        setDataScadenze([]);
    }
    CalcolaTotaleScadenze(result.data);
}

async function retrieveOrdini() {
    let result=[];
    try{
        const token = GetToken(localStorage.getItem("token"));
        const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
        const IDIspettore = GetIDIspettore(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let url=process.env.REACT_APP_API_URL+'ordinecertlist'
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const fd = new FormData();
        if (UserRole===Ruolo_Commerciale || UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico){
            if (parseFloat(IDCommerciale)>0){
                fd.append("Commerciale_id", IDCommerciale); 
            } 
            if (parseFloat(IDIspettore)>0){
                fd.append("Ispettore_id", IDIspettore);    
            }  
        }
        fd.append("RicCommerciale_id", RicIDCommerciale);
        fd.append("IDAzienda", RicIDAzienda);
        fd.append("IDImpianto", RicIDImpianto);
        fd.append("DataOrdineInizio", RicDataOrdineInizio);
        fd.append("DataOrdineFine", RicDataOrdineFine);
        fd.append("Stato", RicStato);
        fd.append("AnnoCompetenza", RicAnnoCompetenza);
        fd.append("LastNumberData", RicNumRecord);
        fd.append("RicLuogo", RicLuogo);
        fd.append("RicTipoLuogo", RicTipoLuogo);
        fd.append("Prezzo", RicPrezzo);
        if (RicSubalterno===true)  fd.append("Subalterno", "1");
        fd.append("Pagato", RicPagato);
        fd.append("ModalPag", RicModalPag);
        fd.append("RicPartitaIva", RicPartitaIva);
                        
        //idazienda/0/codfiscale/0/cognome/0
        result = await ax.post(url,fd,axiosConfig);
        localStorage.setItem("tokenRicercaOrdini","");
        localStorage.setItem("tokenRicercaOrdiniScadenze","");
    }
    catch(error) {
        let msg=""
        if (error.response!==undefined) {
            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
        }
        else
        {
            msg=GetMessage(undefined,"");
        }
        setMsgAlert(msg);
        setOpenAlert(true);

    }
    
    if (result.data!==undefined)    {
        setData(result.data);
    }
    else {
        setData([]);
    }
    CalcolaTotale(result.data);
     
}

const onFilterScadenze = () => {
    setDisableFilterScadenze(true);
    (async () => {   
        setIsLoading(true);    
        await retrieveScadenzeOrdini();
        setIsLoading(false);   
    })(); 
    setPageScadenze(0);
};

useEffect(() => {         
    (async () => {
        setIsLoading(true);
        await retrieveOrdini();
        if (SelectedTab == "2")
        {
            await retrieveScadenzeOrdini();
        }
        setIsLoading(false);
    })(); 
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSending]);   


const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
};  
 
const handleChangePageScadenze = (event, newPage) => {  
    setPageScadenze(newPage);  
};  

const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
};  
  
const handleChangeRowsPerPageScadenze = event => {  
    setRowsPerPageScadenze(+event.target.value);  
    setPageScadenze(0);  
}; 

const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const handleChangeDenseScadenze = (event) => {
    setDenseScadenze(event.target.checked);
};

const onFilter = () => {
    (async () => {   
        setIsLoading(true);    
        await retrieveOrdini();
        setIsLoading(false);   
    })(); 
    setPage(0);
}; 

const handleRicLuogo = (value,text) => {
    setRicTextLuogo(text);
    setRicLuogo(value);
    setDisableFilter(false);
};

const handleRicLuogoScadenze = (value,text) => {
    setRicTextLuogoScadenze(text);
    setRicLuogoScadenze(value);
    setDisableFilterScadenze(false);
};

const handleRicTipoLuogo = (event) => { 
    setRicTipoLuogo(event.target.value);
    
    setRicLuogo("");
    setRicTextLuogo("");
    if (event.target.value==="0"){
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"province");
        setRicOptionLuogoValue("CodProv");
        setRicOptionLuogoName("Provincia");
    }
    else
    {
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"regioni");
        setRicOptionLuogoValue("CodRegione");
        setRicOptionLuogoName("NomeRegione");
    }
    
    setDisableFilter(false);
};

const handleRicTipoLuogoScadenze = (event) => { 
    setRicTipoLuogoScadenze(event.target.value);
    
    setRicLuogoScadenze("");
    setRicTextLuogoScadenze("");
    if (event.target.value==="0"){
        setRicUrlLuogoScadenze(process.env.REACT_APP_API_URL+"province");
        setRicOptionLuogoScadenzeValue("CodProv");
        setRicOptionLuogoScadenzeName("Provincia");
    }
    else
    {
        setRicUrlLuogoScadenze(process.env.REACT_APP_API_URL+"regioni");
        setRicOptionLuogoScadenzeValue("CodRegione");
        setRicOptionLuogoScadenzeName("NomeRegione");
    }
    
    setDisableFilterScadenze(false);
};

const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};

const handleRicAziendaScadenze = (value,text) => {
    setRicAziendaScadenze(text);
    setRicIDAziendaScadenze(value);;
    setDisableFilterScadenze(false);
};

const handleRicImpianto = (value,text) => {
    setRicImpianto(text);
    setRicIDImpianto(value);;
    setDisableFilter(false);
};

const handleRicImpiantoScadenze = (value,text) => {
    setRicImpiantoScadenze(text);
    setRicIDImpiantoScadenze(value);;
    setDisableFilterScadenze(false);
};

const handleRicCommerciale = (value,text) => {
    setRicCommerciale(text);
    setRicIDCommerciale(value);;
    if (value===""){
        setRicSubalterno(false);;
    }
    setDisableFilter(false);
};

const handleRicCommercialeScadenze = (value,text) => {
    setRicCommercialeScadenze(text);
    setRicIDCommercialeScadenze(value);;
    if (value===""){
        setRicSubalternoScadenze(false);;
    }
    setDisableFilterScadenze(false);
};

const handleRicSubalterno = (value) => {  
    setRicSubalterno(!RicSubalterno); 
   
    setDisableFilter(false);
};

const handleRicSubalternoScadenze = (value) => {  
    setRicSubalterno(!RicSubalternoScadenze); 
   
    setDisableFilterScadenze(false);
};

const handleRicDataOrdineInizio = (value) => {  
    setRicDataOrdineInizio(value); 
    if (isDate(RicDataOrdineFine)===""){
        if (DateDiff(value,RicDataOrdineFine)<0){
            setRicDataOrdineFine("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataOrdineFine = (value) => {  
    setRicDataOrdineFine(value);  
    if (isDate(RicDataOrdineInizio)===""){
        if (DateDiff(RicDataOrdineInizio,value)<0){
            setRicDataOrdineInizio("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataScadenzaInizio = (value) => {  
    setRicDataScadenzaInizio(value); 
    if (isDate(RicDataScadenzaFine)===""){
        if (DateDiff(value,RicDataScadenzaFine)<0){
            setRicDataScadenzaFine("");
        }
    }
    setDisableFilterScadenze(false);
};

const handleRicDataScadenzaFine = (value) => {  
    setRicDataScadenzaFine(value);  
    if (isDate(RicDataScadenzaInizio)===""){
        if (DateDiff(RicDataScadenzaInizio,value)<0){
            setRicDataScadenzaInizio("");
        }
    }
    setDisableFilterScadenze(false);
};
const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};

const handleRicPrezzo = (event) => {
    setRicPrezzo(event.target.value);
    setDisableFilter(false);
};

const handleRicAnnoCompetenza = (event) => {
    setRicAnnoCompetenza(event.target.value);
    setDisableFilter(false);
};

const handleRicNumRecord = (event) => {
    setRicNumRecord(event.target.value);
    setDisableFilter(false);
};

const handleRicNumRecordScadenze = (event) => {
    setRicNumRecordScadenze(event.target.value);
    setDisableFilterScadenze(false);
};

const handleRicPagato = (event) => {
    setRicPagato(event.target.value);
    setDisableFilter(false);
};

const handleRicModalPag = (event) => {
    setRicModalPag(event.target.value);
    setDisableFilter(false);
};

const handleRicPartitaIva = (event) => {
    setRicPartitaIva(event.target.value);
    setDisableFilter(false);
};

const handleRicPartitaIvaScadenze = (event) => {
    setRicPartitaIvaScadenze(event.target.value);
    setDisableFilterScadenze(false);
};

const CalcolaTotale = (_mdata)=>{
    let newPrezzoTotale=0;
    let newPrezzoParziale=0;
    let newImponibileTotale=0;
    let newProvvCommTotale=0;
    let newSpeseCommTotale=0;
    let newProvvDirTotale=0;
    let newProvvExecutiveTotale=0;
    let newProvvPartnerTotale=0;
    let newProvvManagerTotale=0;
    let newProvvIspettoriTotale=0;
    let newSpeseDirTotale=0;
    let newProvvCommParziale=0;
    let newSpeseCommParziale=0;
    let newProvvDirParziale=0;
    let newProvvExecutiveParziale=0;
    let newProvvPartnerParziale=0;
    let newProvvManagerParziale=0;
    let newProvvIspettoriParziale=0;
    let newSpeseDirParziale=0;
    let newImponibileParziale=0;
    let newParzialeIncassato=0;
    let newIvaSpesa=0;
    let newTotaleIncassato=0;
    if (_mdata!==undefined){

        for (const item of _mdata) {
            if (parseFloat(item.Annullato)!==1 && parseFloat(item.Approvato)===1){
                newPrezzoParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newPrezzoParziale=newPrezzoParziale + (newPrezzoParziale*parseFloat(item.CodiceIva/100));
                newIvaSpesa=((parseFloat(item.SpeseKm) + parseFloat(item.CostoSpesa))*parseFloat(item.CodiceIvaSpesa/100));
                newPrezzoTotale +=(newPrezzoParziale + newIvaSpesa);

                newImponibileParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newImponibileTotale +=newImponibileParziale;

                newProvvCommParziale=newImponibileParziale * parseFloat(item.ProvvigioneCommerciale)/100;
                newProvvCommTotale +=newProvvCommParziale;

                newSpeseCommParziale=parseFloat(item.SpesaCommerciale);
                newSpeseCommTotale +=newSpeseCommParziale;

                newProvvDirParziale=newImponibileParziale * parseFloat(item.ProvvigioneDirettore)/100;
                newProvvDirTotale +=newProvvDirParziale;

                newProvvExecutiveParziale=newImponibileParziale * parseFloat(item.ProvvigioneExecutive)/100;
                newProvvExecutiveTotale +=newProvvExecutiveParziale;

                newProvvPartnerParziale=newImponibileParziale * parseFloat(item.ProvvigionePartner)/100;
                newProvvPartnerTotale +=newProvvPartnerParziale;

                newProvvManagerParziale=newImponibileParziale * parseFloat(item.ProvvigioneManager)/100;
                newProvvManagerTotale +=newProvvManagerParziale;

                newProvvIspettoriParziale=newImponibileParziale * parseFloat(item.ProvvigioneIspettore)/100;
                newProvvIspettoriTotale +=newProvvIspettoriParziale;

                newSpeseDirParziale=parseFloat(item.SpesaDirettore);
                newSpeseDirTotale +=newSpeseDirParziale;

                if (parseFloat(item.Saldo)===1){
                    newParzialeIncassato=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                    newParzialeIncassato=newParzialeIncassato + (newParzialeIncassato*parseFloat(item.CodiceIva/100));
                    newTotaleIncassato +=newParzialeIncassato + newIvaSpesa;

                }else{
                    if (parseFloat(item.Acconto)>0){
                        newTotaleIncassato +=parseFloat(item.Acconto);
                    }
                }
            }

        }

        setPrezzoTotale(newPrezzoTotale);
        setIncassoTotale(newTotaleIncassato);
        setImponibileTotale(newImponibileTotale);
        setSpeseCommTotale(newSpeseCommTotale);
        setProvvCommTotale(newProvvCommTotale);
        setSpeseDirTotale(newSpeseDirTotale);
        setProvvDirTotale(newProvvDirTotale);
        setProvvExecutiveTotale(newProvvExecutiveTotale);
        setProvvPartnerTotale(newProvvPartnerTotale);
        setProvvManagerTotale(newProvvManagerTotale);
        setProvvIspettoriTotale(newProvvIspettoriTotale);
    }
}

const CalcolaTotaleScadenze = (_mdata)=>{
    let newPrezzoTotale=0;
    let newPrezzoParziale=0;
    let newImponibileTotale=0;
    let newSpeseTotale=0;
    let newImportoSpesa=0;
    let newImponibileParziale=0;
    let newParzialeIncassato=0;
    let newIvaSpesa=0;
    let newTotaleIncassato=0;
    if (_mdata!==undefined){

        for (const item of _mdata) {
            if (parseFloat(item.Annullato)!==1 && parseFloat(item.Approvato)===1){
                newPrezzoParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newPrezzoParziale=newPrezzoParziale + (newPrezzoParziale*parseFloat(item.CodiceIva/100));
                newIvaSpesa=parseFloat(((parseFloat(item.SpeseKm) + parseFloat(item.CostoSpesa))*parseFloat(item.CodiceIvaSpesa/100)).toFixed(2));
                newImportoSpesa = parseFloat(item.SpeseKm) + parseFloat(item.CostoSpesa);
                newPrezzoTotale +=(newPrezzoParziale + newIvaSpesa);
                newSpeseTotale +=(newImportoSpesa + newIvaSpesa);

                newImponibileParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newImponibileTotale +=newImponibileParziale;

                if (parseFloat(item.Saldo)===1){
                    newParzialeIncassato=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                    newParzialeIncassato=newParzialeIncassato + (newParzialeIncassato*parseFloat(item.CodiceIva/100));
                    newTotaleIncassato +=newParzialeIncassato + newIvaSpesa;

                }else{
                    if (parseFloat(item.Acconto)>0){
                        newTotaleIncassato +=parseFloat(item.Acconto);
                    }
                }
            }

        }

        setPrezzoTotaleScadenze(newPrezzoTotale);
        setIncassoTotaleScadenze(newTotaleIncassato);
        setImponibileTotaleScadenze(newImponibileTotale);
        setSpeseTotaleScadenze(newSpeseTotale);
    }
}

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const handleRequestSortScadenze = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderScadenze(isAsc ? 'desc' : 'asc');
    setOrderByScadenze(property);
};

const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "Apri":
            {
                setModOrdine(true);
                break;
            }
        case "Duplica":
            {
                setDuplicaOrdine(true);
                break;
            }
        case "Scarica":
            {
                DownloadDocumento();
                break;
            }
        case "Ricevuta":
            {
                DownloadRicevutaPagamento();
                break;
            }
        default:
    }
};

const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};

let emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) -1;
    if (emptyRows>5){emptyRows=3}

    if (NewOrdine){
        const urlRedirect="/nuovoordineazienda";
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }

    if (ModOrdine){
        const urlRedirect="/modificaordineservizi";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idordine", SelIdOrdine);
        localStorage.setItem("idimpianto", SelIdImpianto);
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
    
    if (DuplicaOrdine){
        const urlRedirect="/nuovoordineservizi";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idimpianto", SelIdImpianto);
        localStorage.setItem("idordineold", SelIdOrdine);
        localStorage.setItem("verificamessaterra", SelVerificaMt);
        localStorage.setItem("verificaexp", SelVerificaExp);
        
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
   
    let IDCommerciale=GetIDCommerciale(localStorage.getItem("token"));
    if (parseFloat(IDCommerciale)===0){
        IDCommerciale="";
    }
  return (  
    
    <div className={classes.root}>
        <OperationDialog classes={classes} onClose={onCloseOperDialog} onSelected={onSelectedItemOperDialog} open={OpenOperDialog} fileName={SelFileName} fileNameRicevuta={SelFileNameRicevuta} rinnovoId = {SelRinnovoId}/>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={SelectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label="Elenco Ordini" value="1" />
                        <Tab label="Scadenze Ordini" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <div className="small" style={{width:"98%"}}>
                        <Row>
                            <Col xl={2} lg={2} md={2} xs={12}>
                                <FormGroup>
                                    <Label for="selRicAnnoCompetenza">Anno</Label>
                                    <Input type="select" className="search" name="selRicAnnoCompetenza" disabled = {isLoading}
                                    id="selRicAnnoCompetenza" placeholder="" bsSize="sm"
                                        value={RicAnnoCompetenza} onChange={handleRicAnnoCompetenza}>
                                            {GetItemLast10Year().map((item) => (
                                                <option key={item.Anno} value={item.Anno}>{item.Anno}</option>
                                            ))}
                                    </Input>
                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                </FormGroup>
                            </Col>
                            <Col xl={2} lg={2} md={2} xs={12}>
                                <FormGroup>
                                    <Label for="selRicNumRecord">Ultimi Ordini</Label>
                                    <Input type="select" className="search" name="selRicNumRecord" disabled = {isLoading}
                                    id="selRicNumRecord" placeholder="" bsSize="sm"
                                        value={RicNumRecord} onChange={handleRicNumRecord}>
                                            <option key="100" value="100">100</option>
                                            <option key="250" value="250">250</option>
                                            <option key="500" value="500">500</option>
                                            <option key="750" value="750">750</option>
                                            <option key="1000" value="1000">1000</option>
                                            <option key="3000" value="3000">3000</option>
                                            <option key="5000" value="5000">5000</option>
                                    </Input>
                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                </FormGroup>
                            </Col>
                            <Col xl={2} lg={3} md={4} xs={12}>
                                <FormGroup>
                                    <Label for="selRicStato">Stato</Label>
                                    <Input type="select" className="search" name="selRicStato" disabled = {isLoading}
                                    id="selRicStato" placeholder="" bsSize="sm"
                                        value={RicStato} onChange={handleRicStato}>
                                            <option key="Tutti" value="">Tutti</option>
                                            {GetItemStatoOrdineCert().map((item) => (
                                                <option key={item.stato_value} value={item.stato_value}>{item.stato_desc}</option>
                                            ))}
                                    </Input>
                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                </FormGroup>
                            </Col>
                            <Col xl={6} lg={5} md={4} xs={12}>
                                <FormGroup>
                                    <Label for="txtRicAzienda">Azienda</Label>
                                    <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" disabled = {isLoading}
                                    url={process.env.REACT_APP_API_URL+'customersinglelist'}
                                    idparent={IDCommerciale} 
                                    optionname="RagioneSociale" optionvalue="id"
                                    customvalue={RicAzienda} onChange={handleRicAzienda} 
                                    className="MuiAutocomplete-inputsm-search"/>
                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{backgroundColor:'#deeaf6'}}>
                                        <Typography ><b>Altri criteri di ricerca</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <Row>
                                                <Col xl={4} lg={4} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Row>
                                                        <Col md="6">
                                                                <label>
                                                                    Commerciale
                                                                </label>
                                                        </Col> 
                                                        {
                                                            RicCommerciale!=="" && (
                                                        <Col md="6">
                                                                <label style={{fontSize:'15px'}}>
                                                                    <input type="checkbox" name="chkRicSubalterno" onChange={handleRicSubalterno} checked={RicSubalterno} disabled = {isLoading} /> {'   '}
                                                                    Includi Subalterni
                                                                </label>
                                                        </Col> )
                                                        }
                                                        </Row>
                                                        <MyAutoComplete id="txtRicCommerciale" name="txtRicCommerciale" 
                                                            url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                                                            idparent={IDCommerciale}
                                                            optionname="DesCommerciale" optionvalue="id"
                                                            customvalue={RicCommerciale} onChange={handleRicCommerciale} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                
                                                <Col xl={2} lg={3} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="selRicStato">Pagamento</Label>
                                                        <Input type="select" className="search" name="selRicPagato" 
                                                        id="selRicPagato" placeholder="" bsSize="sm" disabled = {isLoading}
                                                            value={RicPagato} onChange={handleRicPagato}>
                                                                <option key ="" value="">Tutti</option>
                                                                <option key ="1" value="1">Da Pagare</option>
                                                                <option key ="2" value="2">Pag. Parziali</option>
                                                                <option key ="3" value="3">Saldati</option>
                                                                <option key ="4" value="4">Da Saldare Comm.</option>
                                                                <option key ="5" value="5">Da Saldare Dir.</option>
                                                                <option key ="6" value="6">Da Saldare Executive</option>
                                                                <option key ="7" value="7">Da Saldare Partner</option>
                                                                <option key ="8" value="8">Da Saldare Manager</option>
                                                                <option key ="9" value="9">Da Saldare Ispettore</option>
                                                        </Input>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={4} lg={5} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicImpianto">Impianto</Label>
                                                        <MyAutoComplete id="txtRicImpianto" name="txtRicImpianto" 
                                                            url={process.env.REACT_APP_API_URL+'impiantoeldescsinglelist'}
                                                            idparent={IDCommerciale} 
                                                            optionname="Descrizione" optionvalue="id"
                                                            customvalue={RicImpianto} onChange={handleRicImpianto} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={2} lg={4} md={3} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicPrezzo">Totale</Label>
                                                        <Input type="search" className="search" name="txtRicPrezzo" placeholder=""
                                                        value={RicPrezzo} onChange={handleRicPrezzo}
                                                        bsSize="sm" style={{textAlign:'right'}} disabled = {isLoading}/>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={2} lg={4} md={3} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicDataOrdineInizio">Da Data</Label>
                                                        <MyDatePicker name="txtRicDataOrdineInizio" className="form-control-sm" disabled = {isLoading}
                                                            value={RicDataOrdineInizio} onChange={handleRicDataOrdineInizio} autoComplete="new-password"
                                                            />
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={2} lg={4} md={3} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicDataOrdineFine">A Data</Label>
                                                        <MyDatePicker name="txtRicDataOrdineFine" className="form-control-sm" disabled = {isLoading}
                                                            value={RicDataOrdineFine} onChange={handleRicDataOrdineFine} autoComplete="new-password"
                                                            />
                                                    </FormGroup>
                                                </Col> 
                                                <Col xl={2} lg={4} md={3} xs={12}>
                                                    <FormGroup>
                                                        <Label for="selRicModalPag">Modalità Pag.</Label>
                                                        <Input type="select" className="search" name="selRicModalPag" 
                                                            id="selRicModalPag" placeholder="" bsSize="sm" disabled = {isLoading}
                                                            value={RicModalPag} onChange={handleRicModalPag}>
                                                                <option key="" value="">Tutti</option>
                                                                <option key ="1" value="1">Assegno bancario</option>
                                                                <option key ="2" value="2">Assegno Circolare</option>
                                                                <option key ="3" value="3">Bonifico Bancario</option>
                                                                <option key ="4" value="4">Denaro Contante</option>
                                                                <option key ="5" value="5">Pos</option>
                                                        </Input>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col> 
                                                <Col xl={4} lg={5} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Row>
                                                        <Col md="6">
                                                                <label>
                                                                <input type="radio" name="radioRicLuogo" disabled = {isLoading} value="0" onClick={handleRicTipoLuogo} />
                                                                    &nbsp;Provincia
                                                                </label>
                                                        </Col> 
                                                        <Col md="6">
                                                            <label>
                                                                <input type="radio" name="radioRicLuogo" disabled = {isLoading} value="1" onClick={handleRicTipoLuogo}/>
                                                                &nbsp;Regione
                                                            </label>
                                                        </Col> 
                                                        </Row>
                                                        <MyAutoComplete id="txtRicLuogo" name="txtRicLuogo" 
                                                            url={RicUrlLuogo} 
                                                            optionname={RicOptionLuogoName} optionvalue={RicOptionLuogoValue}
                                                            customvalue={RicTextLuogo} 
                                                            onChange={handleRicLuogo} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label for="txtRicPartitaIVA">Partita IVA</Label>
                                                        <Input type="search" className="search" name="txtRicPartitaIVA" placeholder=""
                                                        value={RicPartitaIva} onChange={handleRicPartitaIva}
                                                        bsSize="sm" disabled = {isLoading}/>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                            <Col xl={2} lg={2} md={2} xs={12}>
                                <FormGroup>
                                    <Label>&nbsp;</Label>
                                    <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <Paper className={classes.paper}> 
                    <Toolbar>
                        {(parseFloat(IDCommerciale)>0  && RicStato!==Stato_Annullato) ? 
                        (<Grid item xs={12}>
                            <Row>
                                <Col xs={6} md={2} lg={2} xl={2}><Button onClick={toggleNewOrdine} className="btn-mbg-cert">Nuovo</Button></Col>
                                <Col xs={6} md={2} lg={4} xl={1}>{UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore ? <ExportToExcelAdmin data={data}/> : <ExportToExcelCommerciale data={data}/> }</Col>
                                <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Imponibile &euro; {ccyFormat(ImponibileTotale)} </b></div></Col>
                                <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Totale &euro; {ccyFormat(PrezzoTotale)} </b></div></Col>
                                <Col lg={2} md={2} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Pagato &euro; {ccyFormat(IncassoTotale)} </b></div></Col>
                            </Row>
                            {
                                (RicPagato==="4" || RicPagato==="5" || RicPagato==="6" || RicPagato==="7" || RicPagato==="8") && (
                                    <>
                                        <Row>
                                            &nbsp;
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Commerciali &euro; {ccyFormat(ProvvCommTotale)} </b></div></Col>
                                            <Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Spese Commerciali &euro; {ccyFormat(SpeseCommTotale)} </b></div></Col>
                                            {(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Direttori &euro; {ccyFormat(ProvvDirTotale)} </b></div></Col>)}
                                            {(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Spese Direttori &euro; {ccyFormat(SpeseDirTotale)} </b></div></Col>)}
                                            {(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Executive) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Executive &euro; {ccyFormat(ProvvExecutiveTotale)} </b></div></Col>)}
                                            {(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Partner) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Partner &euro; {ccyFormat(ProvvPartnerTotale)} </b></div></Col>)}
                                            {(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Manager) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Manager &euro; {ccyFormat(ProvvManagerTotale)} </b></div></Col>)}
                                            {(UserRole ===Ruolo_Admin) && (<Col xs={12} md={3} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Ispettori &euro; {ccyFormat(ProvvIspettoriTotale)} </b></div></Col>)}
                                        </Row>
                                        <Row>
                                            &nbsp;
                                        </Row> 
                                    </>
                                )
                            }
                        </Grid>):(
                        <Row>
                            <Col xs={6} md={4} lg={2} xl={1}>{(UserRole ===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore) ? <ExportToExcelAdmin data={data}/> : <ExportToExcelCommerciale data={data}/> }</Col>
                        </Row>
                        )}
                    </Toolbar> 
                    <TableContainer>  
                        <Table className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                    aria-label="enhanced table" >  
                        <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rowsPerPage}
                                        isScadenze={false}
                                    />  
                        <TableBody>  
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            className="rt-tr-group"
                                        >
                                            <TableCell scope="row">
                                                <Avatar className={classes.BtnEdit}  onClick={() => toggleOperDialog(row.id,row.Azienda_id,row.Impianto_id,row.File_Name,row.FilePagamento_Name,row.NumOrdine,row.DataOrdine,row.VerificaExp,row.VerificaMt, row.Rinnovo_Id)}>
                                                    <MoreVertIcon />
                                                </Avatar>
                                            </TableCell>
                                            <TableCell>{row.NumOrdine}</TableCell>
                                            <TableCell>{row.DataOrdine}</TableCell>
                                            <TableCell>{row.RagioneSociale}</TableCell>
                                            <TableCell>{ (UserRole===Ruolo_Admin || parseFloat(GradoCommerciale) !== Grado_Specialist) ? (row.DesCommerciale):(row.DescImpianto)}</TableCell>
                                            <TableCell>{GetImponibileRow(row.PrezzoTotale,row.Sconto,row.Annullato)}</TableCell>
                                            <TableCell>{row.CodiceIva}</TableCell>
                                            <TableCell>{GetSpese(row.SpeseKm,row.CostoSpesa)}</TableCell>
                                            <TableCell>{GetPrezzoRow(row.PrezzoTotale,row.Sconto,row.CodiceIva,row.Annullato,row.SpeseKm,row.CostoSpesa,row.CodiceIvaSpesa)}</TableCell>
                                            <TableCell>{GetPagatoRow(row.PrezzoTotale,row.Sconto,row.CodiceIva,row.Annullato,row.Acconto,row.Saldo,row.SpeseKm,row.CostoSpesa,row.CodiceIvaSpesa)}</TableCell>
                                            <TableCell>{GetTextStatoOrdineCert(
                                                                        row.Approvato,
                                                                        row.Saldo,
                                                                        row.Annullato,
                                                                        row.Firmato,
                                                                        row.FirmaInVerifica,
                                                                        row.DataVerifica,
                                                                        row.NumDayScadenza,
                                                                        row.GGScadPrimoAvviso,
                                                                        row.Rimandato,
                                                                        row.Rinnovo_id)}</TableCell>
                                            <TableCell style={{textAlign:'center'}}>{GetIconCommissione(row.SaldoCommerciale,row.SaldoDirettore)}</TableCell>
                                            <TableCell>{GetProvvigioneRow(row.PrezzoTotale,row.Sconto,row.Annullato,row.ProvvigioneCommerciale)}</TableCell>
                                            <TableCell>{GetSpesaRow(row.SpesaCommerciale,row.Annullato)}</TableCell>
                                            {
                                                (UserRole===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore) && (
                                                    <TableCell>{GetProvvigioneRow(row.PrezzoTotale,row.Sconto,row.Annullato,row.ProvvigioneDirettore)}</TableCell>
                                                )
                                            }
                                            {
                                                (UserRole===Ruolo_Admin || parseFloat(GradoCommerciale) === Grado_Direttore) && (
                                                    <TableCell>{GetSpesaRow(row.SpesaDirettore,row.Annullato)}</TableCell>
                                                )
                                            }
                                            {
                                                (parseFloat(GradoCommerciale) === Grado_Executive) && (
                                                    <TableCell>{GetProvvigioneRow(row.PrezzoTotale,row.Sconto,row.Annullato,row.ProvvigioneExecutive)}</TableCell>
                                                )
                                            }
                                            {
                                                (parseFloat(GradoCommerciale) === Grado_Partner) && (
                                                    <TableCell>{GetProvvigioneRow(row.PrezzoTotale,row.Sconto,row.Annullato,row.ProvvigionePartner)}</TableCell>
                                                )
                                            }
                                            {
                                                (parseFloat(GradoCommerciale) === Grado_Manager) && (
                                                    <TableCell>{GetProvvigioneRow(row.PrezzoTotale,row.Sconto,row.Annullato,row.ProvvigioneManager)}</TableCell>
                                                )
                                            }
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={UserRole===Ruolo_Admin || parseFloat(GradoCommerciale) == Grado_Direttore ? (16): parseFloat(GradoCommerciale) > Grado_Specialist ? (15) : (14)} />
                                </TableRow>
                            )}
                        </TableBody>  
                        </Table>  
                    </TableContainer>  
                    <TablePagination  
                        rowsPerPageOptions={[20, 40, 60, 80, 100]}  
                        component="div"  
                        count={data.length}  
                        rowsPerPage={rowsPerPage}  
                        page={page}  
                        onChangePage={handleChangePage}  
                        onChangeRowsPerPage={handleChangeRowsPerPage}  
                    />  
                    </Paper>
                    <FormControlLabel
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                            label="Riduci"
                        />
                </TabPanel>
                <TabPanel value="2">
                    <div className="small" style={{width:"98%"}}>
                        <Row>
                            <Col xl={2} lg={2} md={2} xs={12}>
                                <FormGroup>
                                    <Label for="selRicNumRecord">Estrai scadenze</Label>
                                    <Input type="select" className="search" name="selRicNumRecordScadenze" disabled = {isLoading}
                                    id="selRicNumRecordScadenze" placeholder="" bsSize="sm"
                                        value={RicNumRecordScadenze} onChange={handleRicNumRecordScadenze}>
                                            <option key="100" value="100">100</option>
                                            <option key="250" value="250">250</option>
                                            <option key="500" value="500">500</option>
                                            <option key="750" value="750">750</option>
                                            <option key="1000" value="1000">1000</option>
                                            <option key="3000" value="3000">3000</option>
                                            <option key="5000" value="5000">5000</option>
                                    </Input>
                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                </FormGroup>
                            </Col>
                            <Col xl={2} lg={4} md={3} xs={12}>
                                <FormGroup>
                                    <Label for="txtRicDataScadenzaInizio">Da Data</Label>
                                    <MyDatePicker name="txtRicDataScadenzaInizio" className="form-control-sm" disabled = {isLoading}
                                        value={RicDataScadenzaInizio} onChange={handleRicDataScadenzaInizio} autoComplete="new-password"
                                        />
                                </FormGroup>
                            </Col>
                            <Col xl={2} lg={4} md={3} xs={12}>
                                <FormGroup>
                                    <Label for="txtRicDataScadenzaFine">A Data</Label>
                                    <MyDatePicker name="txtRicDataScadenzaFine" className="form-control-sm" disabled = {isLoading}
                                        value={RicDataScadenzaFine} onChange={handleRicDataScadenzaFine} autoComplete="new-password"
                                        />
                                </FormGroup>
                            </Col> 
                            <Col xl={2} lg={2} md={2} xs={12}>
                                <FormGroup>
                                    <Label>&nbsp;</Label>
                                    <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilterScadenze} onClick={onFilterScadenze} >Ricerca</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header-scadenze"
                                    style={{backgroundColor:'#deeaf6'}}>
                                        <Typography ><b>Altri criteri di ricerca</b></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <Row>
                                                <Col xl={6} lg={5} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicAziendaScadenze">Azienda</Label>
                                                        <MyAutoComplete id="txtRicAziendaScadenze" name="txtRicAziendaScadenze" disabled = {isLoading}
                                                        url={process.env.REACT_APP_API_URL+'customersinglelist'}
                                                        idparent={IDCommerciale} 
                                                        optionname="RagioneSociale" optionvalue="id"
                                                        customvalue={RicAziendaScadenze} onChange={handleRicAziendaScadenze} 
                                                        className="MuiAutocomplete-inputsm-search"/>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label for="txtRicPartitaIVAScadenze">Partita IVA</Label>
                                                        <Input type="search" className="search" name="txtRicPartitaIVAScadenze" placeholder=""
                                                        value={RicPartitaIvaScadenze} onChange={handleRicPartitaIvaScadenze}
                                                        bsSize="sm" disabled = {isLoading}/>
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={4} lg={4} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Row>
                                                        <Col md="6">
                                                                <label>
                                                                    Commerciale
                                                                </label>
                                                        </Col> 
                                                        {
                                                            RicCommercialeScadenze!=="" && (
                                                        <Col md="6">
                                                                <label style={{fontSize:'15px'}}>
                                                                    <input type="checkbox" name="chkRicSubalterno" onChange={handleRicSubalternoScadenze} checked={RicSubalternoScadenze} disabled = {isLoading} /> {'   '}
                                                                    Includi Subalterni
                                                                </label>
                                                        </Col> )
                                                        }
                                                        </Row>
                                                        <MyAutoComplete id="txtRicCommercialeScadenze" name="txtRicCommercialeScadenze" 
                                                            url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                                                            idparent={IDCommerciale}
                                                            optionname="DesCommerciale" optionvalue="id"
                                                            customvalue={RicCommercialeScadenze} onChange={handleRicCommercialeScadenze} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={4} lg={5} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtRicImpiantoScadenze">Impianto</Label>
                                                        <MyAutoComplete id="txtRicImpiantoScadenze" name="txtRicImpiantoScadenze" 
                                                            url={process.env.REACT_APP_API_URL+'impiantoeldescsinglelist'}
                                                            idparent={IDCommerciale} 
                                                            optionname="Descrizione" optionvalue="id"
                                                            customvalue={RicImpiantoScadenze} onChange={handleRicImpiantoScadenze} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={4} lg={5} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Row>
                                                        <Col md="6">
                                                                <label>
                                                                <input type="radio" name="radioRicLuogoScadenze" disabled = {isLoading} value="0" onClick={handleRicTipoLuogoScadenze} />
                                                                    &nbsp;Provincia
                                                                </label>
                                                        </Col> 
                                                        <Col md="6">
                                                            <label>
                                                                <input type="radio" name="radioRicLuogoScadenze" disabled = {isLoading} value="1" onClick={handleRicTipoLuogoScadenze}/>
                                                                &nbsp;Regione
                                                            </label>
                                                        </Col> 
                                                        </Row>
                                                        <MyAutoComplete id="txtRicLuogoScadenze" name="txtRicLuogoScadenze" 
                                                            url={RicUrlLuogoScadenze} 
                                                            optionname={RicOptionLuogoScadenzeName} optionvalue={RicOptionLuogoScadenzeValue}
                                                            customvalue={RicTextLuogoScadenze} 
                                                            onChange={handleRicLuogoScadenze} 
                                                            className="MuiAutocomplete-inputsm-search"
                                                            disabled = {isLoading}
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Col>
                        </Row>
                        <div>&nbsp;</div>
                    </div>
                    <Paper className={classes.paper}> 
                    <Toolbar>

                    {(parseFloat(IDCommerciale)>0) &&
                        (<Grid item xs={12}>
                            <Row>
                                <Col xs={6} md={2} lg={4} xl={1}><ExportToExcelScadenze data={dataScadenze}/></Col>
                                <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Imponibile &euro; {ccyFormat(ImponibileTotaleScadenze)} </b></div></Col>
                                <Col lg={2} md={3} xl={2}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Spese &euro; {ccyFormat(SpeseTotaleScadenze)} </b></div></Col>
                                <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Totale &euro; {ccyFormat(PrezzoTotaleScadenze)} </b></div></Col>
                                <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Pagato &euro; {ccyFormat(IncassoTotaleScadenze)} </b></div></Col>
                            </Row>
                        </Grid>)}
                    </Toolbar> 
                        <TableContainer>  
                            <Table className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table" >  
                            <EnhancedTableHead
                                            classes={classes}
                                            order={orderScadenze}
                                            orderBy={orderByScadenze}
                                            onRequestSort={handleRequestSortScadenze}
                                            rowCount={rowsPerPageScadenze}
                                            isScadenze={true}
                                        />  
                            <TableBody>  
                                {stableSort(dataScadenze, getComparator(order, orderBy))
                                    .slice(pageScadenze * rowsPerPageScadenze, pageScadenze * rowsPerPageScadenze + rowsPerPageScadenze)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={index}
                                                className="rt-tr-group"
                                            >
                                                <TableCell scope="row">
                                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleOperDialog(row.id,row.Azienda_id,row.Impianto_id,row.File_Name,row.FilePagamento_Name,row.NumOrdine,row.DataOrdine,row.VerificaExp,row.VerificaMt,row.Rinnovo_Id)}>
                                                        <MoreVertIcon />
                                                    </Avatar>
                                                </TableCell>
                                                <TableCell>{row.NumOrdine}</TableCell>
                                                <TableCell>{row.DataOrdine}</TableCell>
                                                <TableCell>{row.RagioneSociale}</TableCell>
                                                <TableCell>{ (UserRole===Ruolo_Admin || parseFloat(GradoCommerciale) !== Grado_Specialist) ? (row.DesCommerciale):(row.DescImpianto)}</TableCell>
                                                <TableCell>{GetImponibileRow(row.PrezzoTotale,row.Sconto,row.Annullato)}</TableCell>
                                                <TableCell>{row.CodiceIva}</TableCell>
                                                <TableCell>{GetSpese(row.SpeseKm,row.CostoSpesa)}</TableCell>
                                                <TableCell>{GetPrezzoRow(row.PrezzoTotale,row.Sconto,row.CodiceIva,row.Annullato,row.SpeseKm,row.CostoSpesa,row.CodiceIvaSpesa)}</TableCell>
                                                <TableCell>{GetPagatoRow(row.PrezzoTotale,row.Sconto,row.CodiceIva,row.Annullato,row.Acconto,row.Saldo,row.SpeseKm,row.CostoSpesa,row.CodiceIvaSpesa)}</TableCell>
                                                <TableCell>{row.DataScadenza}</TableCell>
                                                <TableCell>{GetTextStatoOrdineCert(
                                                                        row.Approvato,
                                                                        row.Saldo,
                                                                        row.Annullato,
                                                                        row.Firmato,
                                                                        row.FirmaInVerifica,
                                                                        row.DataVerifica,
                                                                        row.NumDayScadenza,
                                                                        row.GGScadPrimoAvviso,
                                                                        row.Rimandato,
                                                                        row.Rinnovo_id)}</TableCell>
                                                
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={12} />
                                    </TableRow>
                                )}
                            </TableBody>  
                            </Table>  
                        </TableContainer>  
                        <TablePagination  
                            rowsPerPageOptions={[20, 40, 60, 80, 100]}  
                            component="div"  
                            count={dataScadenze.length}  
                            rowsPerPage={rowsPerPageScadenze}  
                            page={pageScadenze}  
                            onChangePage={handleChangePageScadenze}  
                            onChangeRowsPerPage={handleChangeRowsPerPageScadenze}  
                        />  
                    </Paper>
                    <FormControlLabel
                            control={<Switch checked={denseScadenze} onChange={handleChangeDenseScadenze} />}
                            label="Riduci"
                        />
                </TabPanel>
            </TabContext>
        </Box>
    </div>  
  );  
} 