import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyDatePicker from '../MyDatePicker';
import Grid from '@material-ui/core/Grid';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewAttestato from './NewAttestato';
import ModAttestato from './ModAttestato';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import MyAlert from '../MyAlert';
import MyConfirm from '../MyConfirm';
import imgSearch from "../../img/search.png";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Ruolo_Azienda,Ruolo_Admin } from '../../Modules/Costanti';
import {GetToken,GetRole,GetIDResponsabile, isDate, DateDiff} from "../../Modules/Funzioni";
import ReactExport from "react-export-excel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function GetOrdine(NumOrdine,DataOrdine){
    let ris="";
    if (NumOrdine!=="" && DataOrdine!=="" && DataOrdine!==null) {
        let mDate = DataOrdine.split("/")
        let yyyy = mDate[2];
        ris=NumOrdine + '/' + yyyy; 
    }
    
    
    return ris;
}

function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Attestati">
                <ExcelColumn label="Titolo" value="Titolo"/>
                <ExcelColumn label="Attestato" value="Descrizione"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Intestatario" value="Intestatario"/>
                <ExcelColumn label="Ordine"
                             value={(col) => GetOrdine(col.NumOrdine,col.DataOrdine)}/>
                <ExcelColumn label="Data Rilasciato" value="DataRilascio"/>
                <ExcelColumn label="Data Scadenza" value="DataScadenza"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'Del', string: false, disablePadding: true, label: '' },
    { id: 'Download', string: false, disablePadding: true, label: '' },
    { id: 'Titolo', string: false, disablePadding: true, label: 'Titolo' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Azienda' },
    { id: 'Intestatario', string: false, disablePadding: true, label: 'Intestatario' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'Ordine' },
    { id: 'DataRilascioCast', string: false, disablePadding: true, label: 'Rilasciato il' },
    { id: 'DataScadenza', string: false, disablePadding: true, label: 'Scade il' },
    { id: 'DataDownloadCast', string: false, disablePadding: true, label: 'Scaricato il' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('asc');
  const [RicStato, setRicStato] = React.useState("1");
  const [RicAzienda, setRicAzienda] = React.useState("");
  const [RicIDAzienda, setRicIDAzienda] = React.useState("");
  const [RicServizio, setRicServizio] = React.useState("");
  const [RicIDServizio, setRicIDServizio] = React.useState("");
  const [RicCodFiscale, setRicCodFiscale] = React.useState("");
  const [RicDataScadenzaDa, setRicDataScadenzaDa] = React.useState("");
  const [RicDataScadenzaA, setRicDataScadenzaA] = React.useState("");
  const [RicCognome, setRicCognome] = React.useState("");
  const [RicNome, setRicNome] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('Cognome');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(15);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewAttestato, setIsOpenNewAttestato] = useState(false);
  const [isOpenModAttestato, setIsOpenModAttestato] = useState(false);
  const [IdModAttestato, setIdModAttestato] = useState("");
  let UserRole =GetRole(localStorage.getItem("token"));
  let User_id =GetIDResponsabile(localStorage.getItem("token"));
  //confirm object
  const [MsgConfirm,setMsgConfirm]=React.useState("Si desidera procedere alla cancellazione dell'attestato?");
  const [OpenConfirm, setOpenConfirm] = React.useState(false);
  const [IdDelConfirm, setIdDelConfirm] = React.useState(false);
  const onCloseConfirm = (event) => {
    setOpenConfirm(false);
};
const toggleDelAttestato = (_Id) => {
    setIdDelConfirm(_Id);
    setOpenConfirm(true);
  }
const onDelConfirm = (event) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'nominadelete/' + IdDelConfirm;
                       
            result = await ax.post(url);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        if (result.data!==undefined)    {
            onLoadData();
        }
        else {
            setData([]);
        }
    })(); 

};
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  const toggleNewAttestato = () => {
    setIsOpenNewAttestato(!isOpenNewAttestato);
  }
  const toggleModAttestato = (id) => {
    setIsOpenModAttestato(!isOpenModAttestato);
    if (!isOpenModAttestato){
        setIdModAttestato(id);
    }
  }
  const DownloadAttestato = (name_attestato,id) => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        let result=[];
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("Attestato_Name", name_attestato);
            fd.append("id", id);
            let url=process.env.REACT_APP_API_URL+'nominaattestato'
            //idazienda/0/codfiscale/0/cognome/0
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'attestato.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        
        (async () => {
            let result=[];
            try{
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let url=process.env.REACT_APP_API_URL+'nominalist'
                const fd = new FormData();
                fd.append("Stato", RicStato);
                fd.append("Nominale", "1");
                if (parseFloat(UserRole)===Ruolo_Azienda){
                    fd.append("Responsabile_id", User_id);
                }
                else{
                    fd.append("Azienda_id", RicIDAzienda);
                }
                fd.append("Servizio_id", RicIDServizio)
                fd.append("Cognome", RicCognome);
                fd.append("Nome", RicNome);
                fd.append("CodFiscale", RicCodFiscale);
                fd.append("DataScadenzaDa", RicDataScadenzaDa);
                fd.append("DataScadenzaA", RicDataScadenzaA);
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   

  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 
const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};

const handleRicServizio = (value,text) => {
    setRicServizio(text);
    setRicIDServizio(value);;
    setDisableFilter(false);
};

const handleRicDataScadenzaDa = (value) => {  
    setRicDataScadenzaDa(value); 
    if (isDate(RicDataScadenzaA)===""){
        if (DateDiff(value,RicDataScadenzaA)<0){
            setRicDataScadenzaA("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataScadenzaA = (value) => {  
    setRicDataScadenzaA(value);  
    if (isDate(RicDataScadenzaDa)===""){
        if (DateDiff(RicDataScadenzaDa,value)<0){
            setRicDataScadenzaDa("");
        }
    }
    setDisableFilter(false);
};

const handleRicCodFiscale = (event) => {
    setRicCodFiscale(event.target.value);
    setDisableFilter(false);
};

const handleRicCognome = (event) => {
    setRicCognome(event.target.value);
    setDisableFilter(false);
};
const handleRicNome = (event) => {
    setRicNome(event.target.value);
    setDisableFilter(false);
};
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};

const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (  
    
    <div className={classes.root}>
        <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>     
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModAttestato && <ModAttestato onUpdate={onLoadData} handleClose={toggleModAttestato} id={IdModAttestato}/>}
        {isOpenNewAttestato && <NewAttestato onInsert={onLoadData} handleClose={toggleNewAttestato}/>}
        <div className="small" style={{width:"98%"}}>
            <Row>
                <Col xl={4} lg={4} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicStato">Stato</Label>
                        <Input type="select" className="search" name="selRicStato" 
                        id="selRicStato" placeholder="" bsSize="sm"
                            value={RicStato} onChange={handleRicStato}>
                                <option key="1" value="1">In Corso</option>
                                <option key="2" value="2">Da Consegnare</option>
                                <option key="3" value="3">In Scadenza Rinnovati</option>
                                <option key="4" value="4">In Scadenza da Rinnovare</option>
                                <option key="5" value="5">Scaduti</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col> 
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataScadenzaDa">Da Data Scad.</Label>
                        <MyDatePicker name="txtRicDataScadenzaDa" className="form-control-sm"
                            value={RicDataScadenzaDa} onChange={handleRicDataScadenzaDa} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataScadenzaA">A Data Scad.</Label>
                        <MyDatePicker name="txtRicDataScadenzaA" className="form-control-sm" 
                            value={RicDataScadenzaA} onChange={handleRicDataScadenzaA} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col> 
                {
                    parseFloat(UserRole)!==Ruolo_Azienda && (
                    <Col xl={4} lg={4} md={6} xs={12}>
                        <FormGroup>
                            <Label for="txtRicAzienda">Azienda</Label>
                            <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                            url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                            optionname="RagioneSociale" optionvalue="id"
                            customvalue={RicAzienda} onChange={handleRicAzienda} 
                            className="MuiAutocomplete-inputsm-search"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>)
                }
                
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicCognome">Cognome</Label>
                        <Input type="search" className="search" name="txtRicCognome" placeholder=""
                        value={RicCognome} onChange={handleRicCognome}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicNome">Nome</Label>
                        <Input type="search" className="search" name="txtRicNome" placeholder=""
                        value={RicNome} onChange={handleRicNome}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={4} lg={4} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicCodFiscale">Cod. Fiscale</Label>
                        <Input type="search" className="search" name="txtRicCodFiscale" placeholder=""
                        value={RicCodFiscale} onChange={handleRicCodFiscale}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                {
                    parseFloat(UserRole)===Ruolo_Admin && (
                    <Col xl={10} lg={10} md={10} xs={12}>
                        <FormGroup>
                            <Label for="txtRicAttestato">Attestato</Label>
                            <MyAutoComplete id="txtRicAttestato" name="txtRicAttestato" 
                            url={process.env.REACT_APP_API_URL+'serviziosinglelistnominale'} 
                            optionname="DesServizio" optionvalue="id"
                            customvalue={RicServizio} onChange={handleRicServizio} 
                            className="MuiAutocomplete-inputsm-search"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>)
                }
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            {
               parseFloat(UserRole)===Ruolo_Admin && (
                <Grid item xs={12}>
                    <Row>
                        <Col xs={6} md={2} lg={2} xl={1}><Button onClick={toggleNewAttestato} className="btn-mbg">Inserisci</Button></Col>
                        <Col xs={6} md={2} lg={2} xl={1}><ExportToExcel data={data}/></Col>
                    </Row>
                </Grid>
                )
            }
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell scope="row">
                                    {
                                    parseFloat(UserRole)===Ruolo_Admin && (
                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleModAttestato(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>)
                                    }
                                </TableCell>
                                <TableCell>
                                {
                                    (parseFloat(UserRole)===Ruolo_Admin && isNaN(parseFloat(row.NumOrdine))) && (
                                        <Avatar className={classes.BtnEdit}  onClick={() => toggleDelAttestato(row.id)}>
                                            <DeleteIcon />
                                        </Avatar>)
                                    }
                                </TableCell>
                                <TableCell>{
                                    row.Attestato_Name!=="" && row.Attestato_Name!==null  &&
                                        (
                                        <Avatar className={classes.BtnEdit}  onClick={() => DownloadAttestato(row.Attestato_Name,row.id)}>
                                            <DownloadIcon />
                                        </Avatar>
                                    )
                                    }
                                    
                                </TableCell>
                                <TableCell>{row.Titolo}</TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{row.Intestatario}</TableCell>
                                <TableCell>{GetOrdine(row.NumOrdine,row.DataOrdine) }</TableCell>
                                <TableCell>{row.DataRilascio}</TableCell>
                                <TableCell>{row.DataScadenza}</TableCell>
                                <TableCell>{row.DataDownload}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={10} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[15, 20, 25]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 