import React, {useRef, useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {Col,Row} from "reactstrap";
import PropTypes from 'prop-types';
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import {GetRole, GetTextNotificationChannel, GetToken} from "../Modules/Funzioni";
import axios from 'axios';
import { Notification_Direction_Inbound, Notification_Direction_Outbound, Ruolo_Admin, app_cpService } from "../Modules/Costanti";
import '../css/Form.css';
import { Avatar, Card, CardActions, CardContent, CardHeader, CircularProgress, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';  
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { MoreVertRounded } from "@material-ui/icons";
import { red } from '@material-ui/core/colors';
import MyConfirm from './MyConfirm';
import MyTextArea from "./MyTextArea";
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        width: '98%',
        padding: '5px',
        marginBottom: theme.spacing(2),
    },
    root: {
        width: '95%',
        marginTop: '50px',
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: '5px'
    },
    avatar: {
        backgroundColor: red[500],
    },
    table: {
        minWidth: 750,
    },
    headerTitle: {
        fontWeight: 'bold'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    BtnEditThread: {
    backgroundColor:"#25d366 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }
    },  
    BtnEditDelete: {
    backgroundColor:"#f50057 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }
    }, 
    BtnEditOld: {
    backgroundColor:"#3f51b5 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));

OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isNew: PropTypes.number.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open , isNew} = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            {
                isNew === 1 && (
                    <ListItem button onClick={() => handleListItemClick("SetOnOld")}>
                        <ListItemAvatar>
                            <Avatar className={classes.BtnEditOld}>
                                <CheckCircleIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>Segna come già letto</ListItemText>
                    </ListItem>

                )
            }
            <ListItem button onClick={() => handleListItemClick("Delete")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEditDelete}>
                    <DeleteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Cancella</ListItemText>
            </ListItem>
        </List>
      </Dialog>
    );
  }
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewThreadNotifications (props) {
  const classes = useStyles();  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [OpenOperDialog,setOpenOperDialog]=useState(false);
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const [TextMessage,setTextMessage]=React.useState("");
  const [ItemSelected, setItemSelected] = React.useState({});
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};

const UserRole =GetRole(localStorage.getItem("token"));
  //-----------
  //confirm object
  const MsgConfirm = "Si desidera procedere alla cancellazione della Notifica?";
  const [OpenConfirm, setOpenConfirm] = React.useState(false);
  
const onCloseConfirm = (event) => {
    setOpenConfirm(false);
};

const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "ViewThread":
            {
                //todo
                break;
            }
        case "SetOnOld":
            {
                onOld(ItemSelected.id);
                break;
            }
        case "Delete":
            {
                toggleDelNotification(ItemSelected.id);
                break;
            }
        
        default:

    }
};

const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};

const onDelConfirm = (event) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'notificationdelete/' + ItemSelected.id;
                       
            result = await ax.post(url);
            normalizeData(ItemSelected.id, 0);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        
    })(); 

};

const onOld = (_id) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'notificationsetold/' + _id;
                       
            //idazienda/0/codfiscale/0/cognome/0
            result = await ax.post(url);
            normalizeData(_id, 1);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        
    })(); 

};

  const normalizeData = (_id , _operationType) => {
    const _data = data;

    switch (_operationType) {
        case 0: //delete
        {
            const index = _data.findIndex(e => e.id === _id);
            if (index > -1) {
                _data.splice(index, 1)
            }
            
            setData(_data);
            break;
        }
        case 1: //old
        {
            const result = _data.map(e => {
                return e.id === _id ? { ...e, IsNew: 0 } : e
            });
            setData(result);
        }
    }
    props.onNormalizeData(_id , _operationType);
  };

  const toggleDelNotification = (_id) => {
    handleSelected(_id)
    setOpenConfirm(true);
  };
  
  const handleSelected = (_id) => {
    const _item = data.filter(e => e.id === _id);
    
    if (_item.length === 1) 
    {
        setItemSelected(_item[0]);
    }
    else 
    {
        setItemSelected(null);
    }
  };

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                
                let url=process.env.REACT_APP_API_URL+'notificationsthread'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("User_id", props.UserId);
                fd.append("Thread", props.SelectedItem.Thread);
                
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   

const toggleOperDialog = (_Id) => {
    handleSelected(_Id);
    setOpenOperDialog(true);
  }

  const handleTextMessage = (event) => {
    setTextMessage(event.target.value);
  }
    
    let HeadClassName="";
    const app_selected =process.env.REACT_APP_APPSELECT;
    if (parseInt(app_selected)===app_cpService) {
        HeadClassName="bg-head-form";
    }
    else{
        HeadClassName ="bg-head-form-cert";
    }
    return (
        <div>
            <MyAlert message={MsgAlert} severity="error" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
            <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/> 
            <OperationDialog classes={classes} onClose={onCloseOperDialog} onSelected={onSelectedItemOperDialog} open={OpenOperDialog} isNew={ItemSelected.IsNew !== undefined ? ItemSelected.IsNew : 0}/>              
            {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Conversazione con {props.SelectedItem.From}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className={classes.root}>
                        <Grid container spacing={3} style={{paddingBottom:'25vh'}}>
                            <Grid item xs={12}>
                                {data.map((row, index) => {
                                    return (
                                        <Row key = {row.id}>
                                            {
                                                parseInt(row.Direction) === Notification_Direction_Outbound && (
                                                    <Col xs={1}>
                                                        &nbsp;
                                                    </Col>
                                                )
                                            }
                                            <Col>
                                                <Card className={classes.root} style={parseInt(row.Direction) === Notification_Direction_Inbound ? parseInt(row.NotificationError) === 1 ? {backgroundColor: '#ffadad'} : {backgroundColor: '#fff'} : {backgroundColor: '#d9fdd3'}}>
                                                    <CardHeader
                                                        className={classes.cardheader}
                                                        avatar={
                                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                                            {row.From.substring(0, 1).toUpperCase()}
                                                        </Avatar>
                                                        }
                                                        action={
                                                        <IconButton aria-label="settings" component="span" onClick={() => toggleOperDialog(row.id)}>
                                                            <MoreVertRounded />
                                                        </IconButton>
                                                        }
                                                        classes={row.IsNew === 1 ? {
                                                            title: classes.headerTitle
                                                        } : null} 
                                                        title={row.From}
                                                        subheader={GetTextNotificationChannel(row.Channel) + ": " + row.DataInserimentoCast}
                                                    />
                                                    {/* <CardMedia
                                                        className={classes.media}
                                                        image="/static/images/cards/paella.jpg"
                                                        title="Paella dish"
                                                    /> */}
                                                    <CardContent>
                                                        <Typography style={{fontSize:'1.5em'}} color="textSecondary" component="p">
                                                            {row.Message}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions 
                                                        disableSpacing
                                                        style={{ width: '100%', justifyContent: 'flex-end' }}
                                                    >
                                                        <IconButton aria-label="delete"  
                                                            color="secondary"
                                                            component="span"
                                                            size="medium"
                                                            onClick={() => toggleDelNotification(row.id)}
                                                        >
                                                            <DeleteIcon style={{minWidth : '1.3em', minHeight : '1.3em'}}/>
                                                        </IconButton>
                                                        <IconButton aria-label="set old"  
                                                            color="primary"
                                                            component="span"
                                                            disabled = {row.IsNew !== 1}
                                                            onClick={() => onOld(row.id)}
                                                        >
                                                            <CheckCircleIcon style={{minWidth : '1.3em', minHeight : '1.3em'}} />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            </Col>
                                            {
                                                parseInt(row.Direction) === Notification_Direction_Inbound && (
                                                    <Col xs={1}>
                                                        &nbsp;
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    );
                                })}
                            </Grid>
                            </Grid>
                    </div>
                </div>
                {
                        UserRole===Ruolo_Admin && (
                        <AppBar position="fixed" style={{top: 'auto', bottom: 0,paddingTop: '10px', paddingBottom:'10px', backgroundColor: '#DAE2ED'}}>
                        <Grid  item xs={12}>
                            <Row key = 'AddMessage'>
                                <Col xs={1}>&nbsp;</Col>
                                <Col xs={9}>
                                    <MyTextArea style = {{width:'100%'}} rowsMin={5} value = {TextMessage} onChange = {handleTextMessage} />
                                </Col>
                                <Col xs={2}><IconButton style={{position: 'absolute', bottom:'0px'}} color='primary' disabled = {TextMessage.length === 0}><SendIcon/></IconButton></Col>
                            </Row>
                        </Grid></AppBar>)
                    }
            </Dialog>
        </div>
    );
}
        