import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

const useStyles = makeStyles((theme) => ({
    myText : {
        boxSizing: 'border-box',
        padding: '8px 12px',
        borderRadius: '8px',
        color: grey[900],
        background: grey[50],
        border: grey[300],
        boxShadow: grey[300],
        '&:hover': {
            borderColor: blue[400]
        },
        '&:focus': {
            borderColor: blue[400],
            boxShadow: '0 0 0 3px ' + blue[200]
        },
        /* firefox */
        '&:focus-visible': {
            outline: 0
        }
    }
    
}));
export default function MyTextArea(props) {
    const classes = useStyles();

  return (
    <TextareaAutosize className={classes.myText} aria-label="write message" defaultValue={props.value} onChange={props.onChange} style={{width:'100%'}} rowsMin={props.rowsMin} placeholder="Scrivi qui il tuo messaggio..." />
  );
}