import React from "react";
import SvgPhone from "../img/phone-icon.png";
import {Input} from "reactstrap";

export default function MyPhoneInput(props) {
  let otherclass="";
  if (props.className!==undefined){
    otherclass=props.className + " ";
  }
  let phoneNumber=props.value;
  if (phoneNumber!=="" && phoneNumber!==null && phoneNumber!== undefined){
    phoneNumber=phoneNumber.replace(" ","");
    phoneNumber=phoneNumber.replace("-","");
    phoneNumber=phoneNumber.replace("/","");
    phoneNumber="tel:+" + phoneNumber;
  }
  
  return (
    <div style={{position:'relative'}}>
        <Input 
        type="text" 
        name={props.name}
        autoComplete="new-password" 
        placeholder={props.placeholder}
        value={props.value} 
        readOnly={props.readOnly}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur= {props.onblur}
      />
      <a href={phoneNumber}><img style={{position:'absolute',top:'0px',right:'0px',height:'100%'}}src={SvgPhone} className={props.imgClass} alt=""/></a>
    </div>
  );
}
