export function GetTextStatoOrdine(_NumServiziNominali,
                                   _NumNomine,
                                   _Approvato,
                                   _Pagato,
                                   _Annullato,
                                   _Firmato,
                                   _InScadenza,
                                   _Scaduti,
                                   _NumServizi,
                                   _NumConsegnati,
                                   _NumScaricabili) {
        let NumServiziNominali=0;
        let NumNomine=0;
        let Approvato=0;
        let Pagato=0;
        let Annullato=0;
        let Firmato=0;
        let InScadenza=0;
        let Scaduti=0;
        let NumServizi=0;
        let NumConsegnati=0;
        let NumScaricabili=0;

        if (!isNaN(parseFloat(_NumServiziNominali))) {
                NumServiziNominali=parseFloat(_NumServiziNominali);
        }
                        
        if (!isNaN(parseFloat(_NumNomine))) 
        {
                NumNomine=parseFloat(_NumNomine);
        }
                
        if (!isNaN(parseFloat(_Approvato))) {
                Approvato=parseFloat(_Approvato);
        }
                
        if (!isNaN(parseFloat(_Pagato))) 
        {
                Pagato=parseFloat(_Pagato)
        }
        if (!isNaN(parseFloat(_Annullato))) 
        {
                Annullato=parseFloat(_Annullato);
        }
        if (!isNaN(parseFloat(_Firmato)))  
        {
                Firmato=parseFloat(_Firmato);
        }
        if (!isNaN(parseFloat(_InScadenza)))
        {
                InScadenza=parseFloat(_InScadenza);
        }
        if (!isNaN(parseFloat(_Scaduti)))
        {
                Scaduti=parseFloat(_Scaduti);
        }
        if (!isNaN(parseFloat(_NumServizi)))
        {
                NumServizi=parseFloat(_NumServizi);
        }
        if (!isNaN(parseFloat(_NumConsegnati)))
        {
                NumConsegnati=parseFloat(_NumConsegnati);
        }
        if (!isNaN(parseFloat(_NumScaricabili))) 
        {
                NumScaricabili=parseFloat(_NumScaricabili);
        }
        

        if (Annullato===1){
                return "Annullato";
        }
        
        if (Firmato===0){
                return "Da Convalidare";
        }
        
        if (Approvato===0){
                return "Da Approvare";
        }
        
        if (NumServiziNominali!==NumNomine){
                return "Incompleto";
        }

        

        if (NumConsegnati!==NumScaricabili){
                
                return "Da Consegnare";
        }
        else {
                if (InScadenza>0){
                        return "In Scadenza";
                }

                if (Scaduti>0 && Scaduti===NumServizi){
                        return "Scaduto";
                }
                
                if (Pagato===0){
                        return "Da Pagare";
                }
                
                else {
                        return "Completato";
                }       
        }
}
export function GetTextStatoContratto(
        _Approvato,
        _Pagato,
        _Annullato,
        _Firmato,
        _NumServizi,
        _NumConsegnati, 
        _DataScadenza) {

                let Approvato=0;
                let Pagato=0;
                let Annullato=0;
                let Firmato=0;
                let NumServizi=0;
                let NumConsegnati=0;
                let Today = new Date();
                var DataScad = new Date(_DataScadenza);
        
        if (!isNaN(parseFloat(_Approvato))) {
                Approvato=parseFloat(_Approvato);
        }

        if (!isNaN(parseFloat(_Pagato))) 
        {
                Pagato=parseFloat(_Pagato)
        }
        if (!isNaN(parseFloat(_Annullato))) 
        {
                Annullato=parseFloat(_Annullato);
        }
        if (!isNaN(parseFloat(_Firmato)))  
        {
                Firmato=parseFloat(_Firmato);
        }
        
        if (!isNaN(parseFloat(_NumServizi)))
        {
                NumServizi=parseFloat(_NumServizi);
        }
        if (!isNaN(parseFloat(_NumConsegnati)))
        {
                NumConsegnati=parseFloat(_NumConsegnati);
        }
        

        if (Annullato===1){
                return "Annullato";
        }

        if (Firmato===0){
                return "Da Convalidare";
        }

        if (Approvato===0){
                return "Da Approvare";
        }
        if (NumServizi !== NumConsegnati){
                return "Da Consegnare";
        }

        if (DataScad < Today){
                return "Scaduto";
        }
        if (NumServizi !== NumConsegnati){
                return "Da Consegnare";
        }
        // if (Pagato===0){
        //         return "Da Pagare";
        // }
        // else {
                return "Completato";
        //}       
}
export function GetItemLast10Year (){
        let _d = new Date();
        let _rows = [];
        for (let i = 0; i < 10; i++) {
                let _result={Anno:_d.getFullYear()-i};
                _rows.push(_result);
        }
        return _rows;
}
export function GetTextStatoPreventivoCert(
        _Approvato,
        _Annullato,
        _Inviato) {

let Approvato=0;
let Annullato=0;
let Inviato=0;


        if (!isNaN(parseFloat(_Approvato))) {
                Approvato=parseFloat(_Approvato);
        }

        if (!isNaN(parseFloat(_Annullato))) 
        {
                Annullato=parseFloat(_Annullato);
        }
        if (!isNaN(parseFloat(_Inviato)))  
        {
                Inviato=parseFloat(_Inviato);
        }
       
        if (Annullato===1){
                return "Annullato";
        }
        
        if (Inviato===0){
                return "Da Inviare";
        }

        if (Approvato===0){
                return "Da Approvare";
        }
        else {
                return "Approvato"
        };
}
export function GetTextStatoOrdineCert(
        _Approvato,
        _Pagato,
        _Annullato,
        _Firmato,
        _FirmaInVerifica,
        _DataVerifica,
        _NumDayScadenza,
        _GGScadPrimoAvviso,
        _Rimandato,
        _Rinnovo_id) {

let Approvato=0;
let Pagato=0;
let Annullato=0;
let Firmato=0;
let FirmaInVerifica=0;
let NumDayScadenza=0;
let GGScadPrimoAvviso=0;
let Rimandato=0;
let Rinnovo_id=0;
let DataVerifica="";

        if (!isNaN(parseFloat(_Approvato))) {
                Approvato=parseFloat(_Approvato);
        }

        if (!isNaN(parseFloat(_Pagato))) 
        {
                Pagato=parseFloat(_Pagato)
        }

        if (!isNaN(parseFloat(_Annullato))) 
        {
                Annullato=parseFloat(_Annullato);
        }
        if (!isNaN(parseFloat(_Firmato)))  
        {
                Firmato=parseFloat(_Firmato);
        }
        
        if (!isNaN(parseFloat(_FirmaInVerifica)))  
        {
                FirmaInVerifica=parseFloat(_FirmaInVerifica);
        }
        if (_DataVerifica!=="" && _DataVerifica!==null)
        {
                DataVerifica=_DataVerifica;
        }
        if (!isNaN(parseFloat(_NumDayScadenza)))
        {
                NumDayScadenza=parseFloat(_NumDayScadenza);
        }
        if (!isNaN(parseFloat(_GGScadPrimoAvviso)))
        {
                GGScadPrimoAvviso=parseFloat(_GGScadPrimoAvviso);
        }
        if (!isNaN(parseFloat(_Rimandato)))
        {
                Rimandato=parseFloat(_Rimandato);
        }
        if (!isNaN(parseFloat(_Rinnovo_id)))
                {
                        Rinnovo_id=parseFloat(_Rinnovo_id);
                }

        if (Annullato===1){
                return "Annullato";
        }
        
        if (Firmato===0 && FirmaInVerifica===0){
                return "Da Convalidare";
        }

        if (Approvato===0){
                return "Da Approvare";
        }
        if (Rimandato>0){
                return "Rimandato";
        } 
        if (DataVerifica===""){
                return "Da Verificare";
        }        
        else {
                if (Rinnovo_id > 0) {
                        return "Rinnovato";
                }
                if (NumDayScadenza>=0 && NumDayScadenza<=GGScadPrimoAvviso){
                        return "In Scadenza";
                }

                if (NumDayScadenza<0){
                        return "Scaduto";
                }

                if (Pagato===0){
                        return "Da Pagare";
                }
                else {
                        return "Pagato";
                }       
        }
}
export function GetTextStatoVerificaCert(
        _Approvato,
        _DataVerifica,
        _DataAppuntamento, 
        _Rimandato,
        _Annullato, 
        ) {

let Annullato=0;
let Approvato=0;
let Rimandato=0;
let DataVerifica="";
let DataAppuntamento="";

        if (!isNaN(parseFloat(_Annullato))) {
                Annullato=parseFloat(_Annullato);
        }
        if (!isNaN(parseFloat(_Approvato))) {
                Approvato=parseFloat(_Approvato);
        }

        if (!isNaN(parseFloat(_Rimandato))) {
                Rimandato=parseFloat(_Rimandato);
        }

        if (_DataVerifica!=="" && _DataVerifica!==null)
        {
                DataVerifica=_DataVerifica;
        }
        
        if (_DataAppuntamento!=="" && _DataAppuntamento!==null)
        {
                DataAppuntamento=_DataAppuntamento;
        }
        if (Annullato===1){
                return "Annullata";
        }
        if (DataAppuntamento===""){
                return "Fissare Appuntamento";
        }
        if (Rimandato===1){
                return "Appuntamento Rimandato";
        }
        if (DataVerifica===""){
                return "Da Verificare";
        }

        if (Approvato===0){
                return "Da Approvare";
        }
        
        return "Completato";
        
}
export function GetTextRuolo(indRuolo,commericaleId,ispettoreId) {
        let ris="";
        let c = require('./Costanti');

        switch (indRuolo){
                case c.Ruolo_Admin: 
                {
                        ris="Admin";
                        break;
                }
                case c.Ruolo_Commerciale: 
                {
                        ris="Commerciale";
                        break;
                }
                case c.Ruolo_Ispettore: 
                {
                        ris="Ispettore";
                        break;
                }
                case c.Ruolo_CommTecnico: 
                {
                        ris="Commerciale Tecnico";
                        break;
                }
                case c.Ruolo_Azienda: 
                {
                        ris="Azienda";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}

export function GetTextTipoVerifica(TipoVerifica) {
        let ris="";
        let c = require('./Costanti');

        switch (TipoVerifica){
                case c.Tipo_verifica_ScaricheAtmosferiche: 
                {
                        ris="Scariche Atmosferiche";
                        break;
                }
                case c.Tipo_verifica_MessaTerra: 
                {
                        ris="Messa a Terra";
                        break;
                }
                case c.Tipo_verifica_RischioEsplosione: 
                {
                        ris="Rischio Esplosione";
                        break;
                }
               
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}

export function GetTextStateCampaign(Stato) {
        let ris="";
        let c = require('./Costanti');

        switch (Stato){
                case c.Campaign_State_DaInviare: 
                {
                        ris="Da Inviare";
                        break;
                }
                case c.Campaign_State_Inviate: 
                {
                        ris="Inviata";
                        break;
                }
                
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}
export function GetTextZona(indZona) {
        let ris="";
        let c = require('./Costanti');

        switch (indZona){
                case c.Zona_cNord: 
                {
                        ris="Centro Nord";
                        break;
                }
                case c.Zona_cSud: 
                {
                        ris="Centro Sud";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}

export function GetTextRischioAteco(indRischio) {
        let ris="";
        let c = require('./Costanti');
        //if (isNaN(indRischio)){
        //        indRischio=0;
        //}
        switch (parseInt(indRischio)){
                case c.Rischio_Indifferente: 
                {
                        ris="Indifferente";
                        break;
                }
                case c.Rischio_Alto: 
                {
                        ris="Alto";
                        break;
                }
                case c.Rischio_Medio: 
                {
                        ris="Medio";
                        break;
                }
                case c.Rischio_Basso: 
                {
                        ris="Basso";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}
export function GetDescrizioneImpianto (IndirizzoImpianto,PotenzaKw,SuperficieLuogo) {
        return IndirizzoImpianto + " Kw " + PotenzaKw + " Mq " + SuperficieLuogo;
}
export function GetTextGrado(indGrado) {
        let ris="";
        let c = require('./Costanti');

        switch (indGrado){
                case c.Grado_Amministratore: 
                {
                        ris="Amministratore";
                        break;
                }
                case c.Grado_Direttore: 
                {
                        ris="Direttore";
                        break;
                }
                case c.Grado_Executive: 
                {
                        ris="Executive";
                        break;
                }
                case c.Grado_Partner: 
                {
                        ris="Partner";
                        break;
                }
                case c.Grado_Manager: 
                {
                        ris="Manager";
                        break;
                }
                case c.Grado_Specialist: 
                {
                        ris="Specialist";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}

export function GetTextNotificationChannel(notificationType) {
        let ris="";
        let c = require('./Costanti');

        switch (notificationType){
                case c.Notification_Channel_WhatsApp: 
                {
                        ris="WhatsApp";
                        break;
                }
                case c.Notification_Channel_Memo: 
                {
                        ris="Memo";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}

export function GetTextStatoImpiegoDipendente(indStato) {
        let ris="";
        let c = require('./Costanti');

        switch (indStato){
                case c.Dip_Operativo: 
                {
                        ris="Operativo";
                        break;
                }
                case c.Dip_Licenziato: 
                {
                        ris="Licenziato";
                        break;
                }
                default:
                {
                        ris="";
                        break;
                }
        }

        return ris;
}
export function GetLocation() {
        return window.location.pathname;
}
export function GetToken(token){
        let matchArray = token.split("|x|");
        return matchArray[0];
}
export function GetRole(token){
        let matchArray = token.split("|x|");
        return parseFloat(matchArray[1]);
}
export function GetIDCommerciale(token){
        let matchArray = token.split("|x|");
        return matchArray[2];
}
export function GetIDResponsabile(token){
        let matchArray = token.split("|x|");
        return matchArray[3];
}
export function GetIDIspettore(token){
        let matchArray = token.split("|x|");
        return matchArray[4];
}
export function GetGradoCommerciale(token){
        let matchArray = token.split("|x|");
        return parseFloat(matchArray[5]);
}
export function GetIDIspettoreEmulato(token){
        let matchArray = token.split("|x|");
        return matchArray[6];
}
export function GetTitServizi(){
        const ArrayRis = [
                { titolo_name: 'ALIMENTARISTA',titolo_value:'ALIMENTARISTA'},
                { titolo_name: 'ANTINCENDIO',titolo_value:'ANTINCENDIO'},
                { titolo_name: 'CONTRATTO ANNUALE',titolo_value:'CONTRATTO ANNUALE'},
                { titolo_name: 'COVID',titolo_value:'COVID'},
                { titolo_name: 'CORSO',titolo_value:'CORSO'},
                { titolo_name: 'DIRI ART 37/08',titolo_value:'DIRI ART 37/08'},
                { titolo_name: 'DOGENZA',titolo_value:'DOGENZA'},
                { titolo_name: 'DPI III CAT. ANTICADUTA',titolo_value:'DPI III CAT. ANTICADUTA'},
                { titolo_name: 'DVR',titolo_value:'DVR'},
                { titolo_name: 'DVR BIOLOGICO',titolo_value:'DVR BIOLOGICO'},
                { titolo_name: 'DVR CANCEROGENI',titolo_value:'DVR CANCEROGENI'},
                { titolo_name: 'DVR CHIMICO',titolo_value:'DVR CHIMICO'},
                { titolo_name: 'DVR ELETTRICO',titolo_value:'DVR ELETTRICO'},
                { titolo_name: 'DVR FULMINAZIONE',titolo_value:'DVR FULMINAZIONE'},
                { titolo_name: 'DVR GENERICO',titolo_value:'DVR GENERICO'},
                { titolo_name: 'DVR INCENDIO',titolo_value:'DVR INCENDIO'},
                { titolo_name: 'DVR LEGIONELLA',titolo_value:'DVR LEGIONELLA'},
                { titolo_name: 'DVR MICROCLIMA',titolo_value:'DVR MICROCLIMA'},
                { titolo_name: 'DVR MOVIMENTAZIONE MANUALE',titolo_value:'DVR MOVIMENTAZIONE MANUALE'},
                { titolo_name: 'DVR PEE',titolo_value:'DVR PEE'},
                { titolo_name: 'DVR RUMORE',titolo_value:'DVR RUMORE'},
                { titolo_name: 'DVR STRESS',titolo_value:'DVR STRESS'},
                { titolo_name: 'DVR VIBRAZIONE',titolo_value:'DVR VIBRAZIONE'},
                { titolo_name: 'DVR VIDEOTERMINALI',titolo_value:'DVR VIDEOTERMINALI'},
                { titolo_name: 'DUVRI',titolo_value:'DUVRI'},
                { titolo_name: 'ECM',titolo_value:'ECM'},
                { titolo_name: 'ESCAVATORISTI',titolo_value:'ESCAVATORISTI'},
                { titolo_name: 'FORMAZIONE LAVORATORI',titolo_value:'FORMAZIONE LAVORATORI'},
                { titolo_name: 'GRU',titolo_value:'GRU'},
                { titolo_name: 'MANUALE HACCP',titolo_value:'MANUALE HACCP'},
                { titolo_name: 'MOTOSEGA',titolo_value:'MOTOSEGA'},
                { titolo_name: 'MULETTO',titolo_value:'MULETTO'},
                { titolo_name: 'NOMINA MEDICO',titolo_value:'NOMINA MEDICO'},
                { titolo_name: 'PIMUS',titolo_value:'PIMUS'},
                { titolo_name: 'PLE',titolo_value:'PLE'},
                { titolo_name: 'PONTEGGIO',titolo_value:'PONTEGGIO'},
                { titolo_name: 'POS',titolo_value:'POS'},
                { titolo_name: 'PRE',titolo_value:'PRE'},
                { titolo_name: 'PREPOSTO',titolo_value:'PREPOSTO'},
                { titolo_name: 'PRIMO SOCCORSO',titolo_value:'PRIMO SOCCORSO'},
                { titolo_name: 'PRIVACY',titolo_value:'PRIVACY'},
                { titolo_name: 'RLS',titolo_value:'RLS'},
                { titolo_name: 'RSPP',titolo_value:'RSPP'},
                { titolo_name: 'TAMPONI',titolo_value:'TAMPONI'},
                { titolo_name: 'TRATTORE',titolo_value:'TRATTORE'},
                { titolo_name: 'VERIFICA IMPIANTO',titolo_value:'VERIFICA IMPIANTO'},
                { titolo_name: 'VIDEOSORVEGLIANZA',titolo_value:'VIDEOSORVEGLIANZA'},
                { titolo_name: 'VISITA MEDICA',titolo_value:'VISITA MEDICA'},
            ];
        return (ArrayRis);
}
export function GetListRuoloCommercialeServ(){
        const ArrayRis = [
                { value: '1',description:'Amministratore'},
                { value: '2',description:'Direttore'},
                { value: '6',description:'Specialist'},
            ];
        return (ArrayRis);
}
export function GetListRuoloCommercialeCert(){
        const ArrayRis = [
                { value: '1',description:'Amministratore'},
                { value: '2',description:'Direttore'},
                { value: '3',description:'Executive'},
                { value: '4',description:'Partner'},
                { value: '5',description:'Manager'},
                { value: '6',description:'Specialist'},
            ];
        return (ArrayRis);
}
export function GetExtensionFile(fileName){
        let pos= fileName.lastIndexOf(".");
        if (pos>0) return fileName.substring(pos+1);
        return "";
}

export function GetModalSpesaDirettore(_modal){
        let c = require('./Costanti');
        switch (_modal){
                case c.ModalSpesa_Fisso:
                        return "Fisso";
                case c.ModalSpesa_Percentuale:
                        return "Percentuale";
                default:
                        return "";
        }
}

export function GetBlobTypeByExtension(fileExtension){

        switch (fileExtension.toLowerCase()){
                case "pdf":{
                        return 'application/pdf';
                }
                default: {
                        return 'image/' + fileExtension;
                }
        }
}
export function GetItemStatoOrdine(){

        let c = require('./Costanti');

        const ArrayRis = [
                { stato_desc: 'Da Convalidare',stato_value:c.Stato_DaConvalidare},
                { stato_desc: 'Da Completare',stato_value:c.Stato_DaCompletare},
                { stato_desc: 'Da Approvare',stato_value:c.Stato_DaApprovare},
                { stato_desc: 'Da Consegnare',stato_value:c.Stato_DaConsegnare},
                { stato_desc: 'Da Pagare',stato_value:c.Stato_DaPagare},
                { stato_desc: 'Completato',stato_value:c.Stato_Completato},
                { stato_desc: 'In Scadenza',stato_value:c.Stato_InScadenza},
                { stato_desc: 'Scaduti',stato_value:c.Stato_Scaduti},
                { stato_desc: 'Annullato',stato_value:c.Stato_Annullato},
            ];
        return (ArrayRis);
}
export function GetItemStatoContratto(){

        let c = require('./Costanti');

        const ArrayRis = [
                { stato_desc: 'Da Convalidare',stato_value:c.Stato_DaConvalidare},
                { stato_desc: 'Da Completare',stato_value:c.Stato_DaCompletare},
                { stato_desc: 'Da Approvare',stato_value:c.Stato_DaApprovare},
                { stato_desc: 'Da Consegnare',stato_value:c.Stato_DaConsegnare},
                { stato_desc: 'Completato',stato_value:c.Stato_Completato},
                { stato_desc: 'Scaduti',stato_value:c.Stato_Scaduti},
                { stato_desc: 'Annullato',stato_value:c.Stato_Annullato},
            ];
        return (ArrayRis);
}
export function GetItemTipoVerifica(){

        let c = require('./Costanti');

        const ArrayRis = [
                { tipo_desc: 'Scariche atmosferiche',tipo_value:c.Tipo_verifica_ScaricheAtmosferiche},
                { tipo_desc: 'Messa a terra',tipo_value:c.Tipo_verifica_MessaTerra},
                { tipo_desc: 'Rischio Esplosione',tipo_value:c.Tipo_verifica_RischioEsplosione},
            ];
        return (ArrayRis);
}
export function GetItemTipoVerificaEstesa(){

        const ArrayRis = [
                { tipo_desc: 'Scariche atmosferiche',tipo_value:'ATM'},
                { tipo_desc: 'Messa a terra (TT)',tipo_value:'TT'},
                { tipo_desc: 'Messa a terra (TN)',tipo_value:'TN'},
                { tipo_desc: 'Rischio Esplosione',tipo_value:'EXP'},
            ];
        return (ArrayRis);
}
export function GetItemStatoOrdineCert(){

        let c = require('./Costanti');

        const ArrayRis = [
                { stato_desc: 'Da Convalidare',stato_value:c.Stato_DaConvalidare},
                { stato_desc: 'Da Approvare',stato_value:c.Stato_DaApprovare},
                { stato_desc: 'Da Verificare',stato_value:c.Stato_DaConsegnare},
                { stato_desc: 'Da Pagare',stato_value:c.Stato_DaPagare},
                { stato_desc: 'Pagato',stato_value:c.Stato_Completato},
                { stato_desc: 'In Scadenza',stato_value:c.Stato_InScadenza},
                { stato_desc: 'Scaduto',stato_value:c.Stato_Scaduti},
                { stato_desc: 'Annullato',stato_value:c.Stato_Annullato},
                { stato_desc: 'Rimandato',stato_value:c.Stato_Rimandato},
                { stato_desc: 'Rinnovato',stato_value:c.Stato_Rinnovato},
            ];
        return (ArrayRis);
}
export function GetItemStatoPreventivoCert(){

        let c = require('./Costanti');

        const ArrayRis = [
                { stato_desc: 'Da Inviare',stato_value:c.Stato_Preventivo_DaInviare},
                { stato_desc: 'Da Approvare',stato_value:c.Stato_Preventivo_DaApprovare},
                { stato_desc: 'Approvato',stato_value:c.Stato_Preventivo_Approvato},
                { stato_desc: 'Annullato',stato_value:c.Stato_Preventivo_Annullato},
                
            ];
        return (ArrayRis);
}
export function GetItemStatoVerificaCert(){

        let c = require('./Costanti');

        const ArrayRis = [
                { stato_desc: 'Fissare Appuntamento',stato_value:c.Verifica_FissareAppuntamento},
                { stato_desc: 'Da Verificare',stato_value:c.Verifica_DaVerificare},
                { stato_desc: 'Da Approvare',stato_value:c.Verifica_DaApprovare},
                { stato_desc: 'Completata',stato_value:c.Verifica_Completata},
                { stato_desc: 'Appuntamento Rimandato',stato_value:c.Verifica_AppuntamentoRimandato},
            ];
        return (ArrayRis);
}

export function GetElencoStatoDip(){
        const ArrayRis = [
                { stato_name: 'Operativo',stato_value:'0'},
                { stato_name: 'Licenziato',stato_value:'1'},
            ];
        return (ArrayRis);
}
export function GetElencoStatoImpElettrico(){
        const ArrayRis = [
                { stato_name: 'Attivo',stato_value:'0'},
                { stato_name: 'Chiuso',stato_value:'1'},
            ];
        return (ArrayRis);
}
export function GetTipoDistintaProvvName(type) {
        let c = require('./Costanti');
        switch (type) {
                case c.TipoDistintaProvv_Commerciale:
                        return "Commerciale";  
                case c.TipoDistintaProvv_Ispettore:
                        return "Ispettore";
                default:
                        return "";
        }
}
export function ccyFormat(num) {
        if (isNaN(num) || num === undefined || typeof num !== "number") return 0;
        return `${num.toFixed(2)
                .replace('.', ',') // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
      }
export function ccyFormatValue(num) {
        return Number(num).toFixed(2)
                .replace('.', ',') // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      }
export function isDate(dateStr){
        let matchArray = dateStr.split("/"); // è del formato corretto?
        if (matchArray[1] === undefined) {
                return "La data deve essere inserita come gg/mm/aaaa.";
        }

        // esegue il parsing della data in variabili
        let giorno = parseFloat(matchArray[0]);
        let mese = parseFloat(matchArray[1]);
        let anno = parseFloat(matchArray[2]);

        if (mese < 1 || mese > 12 || isNaN(mese)) //controlla il mese
        {
                return "Il mese deve essere tra 1 e 12.";
        }

        if ((mese === 4 || mese === 6 || mese === 9 || mese === 11) && giorno === 31) {
                return "Il mese " + mese + " non ha 31 giorni!";
        }

        if (mese === 2) { // controlla il bisestile
                const bises = (anno % 4 === 0 && (anno % 100 !== 0 || anno % 400 === 0));
                if (giorno > 29 || (giorno === 29 && !bises)) {
                return "Febbraio " + anno + " non ha " + giorno + " giorni!";
                }
        }

        if (giorno < 1 || giorno > 31 || isNaN(giorno)) {
                return "Il giorno deve essere tra 1 e 31.";
        }

        if (anno > 2100 || anno < 1900 || isNaN(anno)) {
                return "l'anno deve essere compreso tra 1900 e 2100.";

        }
        return "";  // data valida
}
export function add_years(dt,n) 
 {
         if (dt!=="" && dt!==null && dt!==undefined){
                let nDate = dt.split("/"); 
                var dd = parseFloat(nDate[0]);
                var mm = parseFloat(nDate[1]);
                var yyyy =parseFloat(nDate[2]) + parseFloat(n);
                return dd + "/" + mm + "/" + yyyy;
         }
        else {
                return "";      
        }
 }
 export function add_months(dt,n) 
 {
         if (dt!=="" && dt!==null && dt!==undefined && parseInt(n) > 0){
                let lastDayOfMonth = ["31","28","31","30","31","30","31","31","30","31","30","31"];

                let nDate = dt.split("/"); 
                var dd = nDate[0];
                var day = "01"; 
                var mm = nDate[1];
                var yyyy =nDate[2];
                var date = new Date(yyyy + "-" + mm + "-" + day + "T00:00:00");
                date.setMonth(date.getMonth() + parseInt(n));
                if (parseInt(dd) > parseInt(lastDayOfMonth[date.getMonth()])) {
                        if ((date.getMonth() + 1) === 2) {
                          if (parseInt(date.getFullYear()) % 400 == 0
                              || (parseInt(date.getFullYear()) % 4 == 0 && parseInt(date.getFullYear()) % 100 !== 0)) {
                                dd = "29";
                          }
                          else {
                                dd = parseInt(lastDayOfMonth[date.getMonth()]);
                          }
                        }
                        else {
                          dd = parseInt(lastDayOfMonth[date.getMonth()]);
                        }  
                  }
                return ("0" + dd).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
         }
        else {
                return "";      
        }
        
 }
 export function get_years(dt) 
 {
         if (dt!=="" && dt!==null && dt!==undefined){
                let nDate = dt.split("/"); 
                var yyyy =parseFloat(nDate[2]);
                return yyyy;
         }
        else {
                return "";      
        }
        
 
 }
 export function DateDiff(dt2,dt1) 
 {
        var str1 = dt1.split('/');
        var str2 = dt2.split('/');
        // yyyy, mm, dd
        var dataa = new Date(str1[2], str1[1] - 1, str1[0]);
        var datab = new Date(str2[2], str2[1] - 1, str2[0]);
        var diffMilli = dataa.getTime() - datab.getTime();
        var divisore = 86400000;
        return Math.round(diffMilli / divisore);
 }
 export function VerifyPDF(_file) 
 {
        if (_file.type!=="application/pdf"){
                return "Sono ammessi solo file PDF!";
        }
        
        if (_file.size>3000000){
                
                return "selezionare file che non superino i 3 mb!";
        }

        return "";
 
 }

 export function VerifyImage(_file) 
 {
        if (!_file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                return "selezionare un immagine valida!";
              }
        
        if (_file.size>2000000){
                
                return "selezionare file che non superino 2Mb!";
        }

        return "";
 
 }